import { API_HOST } from '@/app/app.config'
import { log, warn } from '@lavarage/utils'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { type TephraUser } from '../../../../functions/tephras.mjs'

type TephraUsers = TephraUser[] | []

async function fetchTopTephraUsers() {
  try {
    const { data } = await axios.get('/tephras', { baseURL: API_HOST })
    const topTephraUsers = data as TephraUsers

    log('Fetching tephras...', 'OK', topTephraUsers)

    return topTephraUsers
  }
  catch (err) {
    warn('Fetching tephras...', 'ERR', `${err}`)

    throw err
  }
}

export const useTopTephraUsersQuery = () => {
  const {
    data: topTephraUsers,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['topTephraUsers'],
    queryFn: fetchTopTephraUsers,
    initialData: [],
  })

  return {
    topTephraUsers,
    isLoading,
    isError,
    refetch,
  }
}
