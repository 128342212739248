import { useLavarage } from '@/app/providers/LavarageProvider'
import { TokenService } from '@/services'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'
import { HOT_COIN_ADDRESSES_ARRAY, NEW_COIN_ADDRESSES_ARRAY } from '../../app.config.js'

const tokenService = new TokenService()

export const useTokensQuery = (list: 'base' | 'quote' | 'all') => {
  const lavarages = useLavarage()

  const fetchController = useRef<AbortController | null>(null)
  const firstRenderRef = useRef(true)

  const fetchTokens = useCallback(async () => {
    if (fetchController.current) {
      fetchController.current.abort()
    }

    fetchController.current = new AbortController()
    const signal = fetchController.current.signal

    try {
      if (list === 'all') {
        const fetchedTokens = await tokenService.fetchTokens({ cache: firstRenderRef.current, signal })
        fetchedTokens.forEach(token => {
          if (NEW_COIN_ADDRESSES_ARRAY.includes(token.address)) {
            token.isNewCoin = true
          }
          if (HOT_COIN_ADDRESSES_ARRAY.includes(token.address)) {
            token.isTopCoin = true
          }
        })
        return fetchedTokens
      }

      if (signal.aborted) return

      firstRenderRef.current = false
    }
    catch (error) {
      if (signal.aborted) return
      console.error('Error fetching tokens:', error)
      return []
    }
    finally {
      fetchController.current = null
    }
  }, [list, lavarages])

  const {
    data: tokens,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['tokens', list],
    queryFn: fetchTokens,
    enabled: !!lavarages,
    refetchInterval: 60_000,
    retry: 5,
  })

  return { tokens, refetch, isLoading, isError }
}
