import { BaseService } from '@/services/BaseService'
import { type TokenInfo } from '@/services/types'
import axios from 'axios'
import { API_HOST } from '../app/app.config.js'

function createAxiosHeaders(cache = true) {
  const headers = new axios.AxiosHeaders()
  if (!cache) {
    headers.set('Cache-Control', 'no-cache')
  }
  return headers
}
export class TokenService extends BaseService {
  async fetchTokens({ cache = true, signal }: { cache?: boolean; signal?: AbortSignal }, whitelistedAddresses?: string[]) {
    const url = `${API_HOST}/tokens?cache=${cache}`
    const headers = createAxiosHeaders(cache)
    const response = await this.http<TokenInfo[]>(url, { headers, signal })
    return whitelistedAddresses ? response.data.filter(token => whitelistedAddresses.includes(token.address)) : response.data
  }

  async getTokens() {
    return this.fetchTokens({ cache: true })
  }
  // TODO: Implement the following methods to support multicurrency
  // async getWhitelistedTokens() {
  //   const tokens = await this.getTokens()
  //   if (!tokens) return []
  //   return tokens.filter(token => token.whitelisted)
  // }

  async getTokenByAddress(address: string) {
    const tokens = await this.getTokens()
    return tokens.find(token => token.address === address)
  }

  async getTokenByName(name: string) {
    const tokens = await this.getTokens()
    return tokens.find(token => token.name === name)
  }

  async getTokenBySymbol(symbol: string) {
    const tokens = await this.getTokens()
    return tokens.find(token => token.symbol === symbol)
  }
  // TODO: Implement the following methods to support multicurrency
  // async revalidateBasedOnWhitelistedTokens({
  //   whitelistedAddresses,
  //   cache,
  //   signal,
  // }: {
  //   whitelistedAddresses: string[]
  //   cache: boolean
  //   signal?: AbortSignal
  // }) {
  //   const whitelistedTokensDb = await this.getWhitelistedTokens()
  //   const whitelistedTokenAddressesDb = whitelistedTokensDb.map(token => token.name)
  //   if (whitelistedAddresses.length === whitelistedTokenAddressesDb.length) {
  //     return this.getTokens()
  //   }

  //   return this.fetchTokens({ cache, signal }, whitelistedAddresses)
  // }
}
