import { type Program } from '@coral-xyz/anchor'
import { Pool } from '@lavarage/entities'
import type { LavarageIdl, LavarageV2Idl } from '@lavarage/idls'

export class PoolService {
  constructor(private program: Program<typeof LavarageIdl> | Program<typeof LavarageV2Idl>) {}

  async getPools(): Promise<Pool[]> {
    const pools = await this.program.account.pool.all()
    return pools.map(p => Pool.factory(p, this.program.programId.toBase58()))
  }

  async getPoolByKey(poolKey: string) {
    const pools = await this.program.account.pool.all()
    const pool = pools.find(({ publicKey }) => publicKey.toBase58() === poolKey)

    return Pool.factory(pool, this.program.programId.toBase58())
  }
}
