import { Conditional, LSpan } from '@/app/components'
import { useVaultBalanceQuery } from '@/app/hooks/queries'
import { useStendingService } from '@/app/providers/StendingProvider'
// import { usePreferencesStore } from '@/app/stores'
// import { CRYPTO_DECIMAL_POINTS, FIAT_DECIMAL_POINTS } from '@/config'
import { useEffect, useState } from 'react'
// import { useShallow } from 'zustand/react/shallow'
import { useShallow } from 'zustand/react/shallow'
import { IconArrow } from '../../../../assets/index.js'
import { usePreferencesStore } from '../../../stores/index.js'
// import { usePriceContext } from '../../../providers/PriceProvider.js'
// import { VaultUtils } from '../utils/VaultUtils'

export function StakeUnstakeMainSection() {
  const stendingService = useStendingService()
  if (!stendingService) return null
  const { vaultBalance, fetchVaultBalance, isLoading } = useVaultBalanceQuery()
  const [lstSolAmount, setLstSolAmount] = useState<number>(0)
  const [pnl, setPnL] = useState<number>(0)
  const [pnlLastUpdate, setPnLLastUpdate] = useState<number>(0)
  const [pnlHist, setPnLHist] = useState<number>()
  // const [isUsdModeOn, setIsUsdModeOn] = usePreferencesStore(useShallow(s => [
  //   s.isUsdModeOn.staking,
  //   () => s.setIsUsdModeOn({ ...s.isUsdModeOn, staking: !s.isUsdModeOn.staking }),
  // ]))

  // const { priceUsdc, usdcPriceLoading, usdcPriceError, refetch } = usePriceContext()

  useEffect(() => {
    stendingService?.getUserLstSolBalance().then(res => {
      setLstSolAmount(res / 1_000_000_000)
    })

    stendingService?.getCurrentPnl().then(res => {
      setPnL(res.pnl)
      setPnLLastUpdate(res.lastUpdate)
      setPnLHist(res.hist)
    })
  }, [stendingService, vaultBalance])

  // const decimalPoints = isUsdModeOn ? FIAT_DECIMAL_POINTS : CRYPTO_DECIMAL_POINTS

  // const localFormatCurrency = useCallback(
  //   (val: number) => {
  //     if (typeof priceUsdc === 'undefined' || priceUsdc === null) {
  //       return '--'
  //     }
  //     else return VaultUtils.localFormatCurrency(val, priceUsdc, isUsdModeOn, decimalPoints)
  //   },
  //   [priceUsdc, isUsdModeOn, decimalPoints],
  // )

  const historicalPnL = (vaultBalance?.nav - 1) * 100
  // TODO: remove deployedAPY if not needed anymore and refactor the code
  // const deployedAPY = parseFloat(VaultUtils.calculateApy(pnlHist, pnl, vaultBalance?.deployed.toNumber(), pnlLastUpdate))
  const combinedAPY = (historicalPnL / (Date.now() / 1000 - 1716350663)) * 31536000
  // const delegatedValue = parseFloat(vaultBalance?.delegated.dividedBy(vaultBalance.total).multipliedBy(100).toFixed(1))
  // const deployedValue = parseFloat(vaultBalance?.deployed.dividedBy(vaultBalance.total).multipliedBy(100).toFixed(1))

  // TODO this data should be fetched from the server
  const currentCapacity = Math.floor(vaultBalance?.total?.toNumber())
  const maxCapacity = 5000
  // const income = 0.24
  // const apy = Math.round(combinedAPY)
  const apy = Math.round(combinedAPY * 10) / 10
  // const isLoading = false

  const progressOfCapacity = Math.floor((currentCapacity / maxCapacity) * 100)
  const capacityLeft = maxCapacity - currentCapacity
  const [isStakingPoolOverfilled, setIsStakingPoolOverfilled] = usePreferencesStore(useShallow(s => [
    s.isStakingPoolOverfilled,
    s.setIsStakingPoolOverfilled,
  ]))

  useEffect(() => {
    setIsStakingPoolOverfilled(currentCapacity >= maxCapacity)
  }, [currentCapacity, maxCapacity, setIsStakingPoolOverfilled])

  return (
    <>
      <section className='bg-main/5 mb-[10px] flex w-full flex-col gap-[10px] rounded-lg p-[20px]'>
        <div className='flex justify-start font-bold'>
          <LSpan
            className='text-[30px] leading-[36px]'
            // isLoading={isLoading}
            text='Lava SOL Meme Liquidity I'
            textSize={36}
          />
        </div>
        <div className='flex items-center justify-between text-sm'>
          <span className='flex items-center gap-1 opacity-40'>
            <span>Capacity: </span>
            <LSpan
              isLoading={isLoading}
              text={currentCapacity.toString()}
            />
            <span>/</span>
            <LSpan
              // isLoading={isLoading}
              text={maxCapacity.toString()}
              textSize={14}
            />
            <span>SOL</span>
          </span>
          <Conditional if={!isStakingPoolOverfilled}>
            <span className='flex items-center text-base'>
              <LSpan
                className={isLoading ? 'text-red' : 'text-gradient'}
                isLoading={isLoading}
                text={progressOfCapacity.toString()}
                textSize={14}
              />
              <span className='text-gradient'>% Filled</span>
            </span>
          </Conditional>
          <Conditional if={isStakingPoolOverfilled}>
            <span className='text-base'>
              <span className='text-gradient'>Overfilled</span>
            </span>
          </Conditional>
        </div>
        <progress className='progress progress-purple bg-main h-[14px]' max='100' value={progressOfCapacity}/>
        <span className='flex justify-center text-[20px] font-bold leading-[24.2px]'>
          Historical Performance
        </span>
        <div className='flex items-center justify-center text-sm'>
          <div className='flex w-[234px] items-center justify-center gap-[14px]'>
            <div className='flex flex-col items-start justify-start'>
              {/* <div className='text-[12px] opacity-40'>Income</div>
              <LSpan
                className={`${getTextColorByString(formatPercentWithoutPlus(income, 1))} text-[20px] font-bold`}
                isLoading={isLoading}
                text={`${income} SOL`}
                textSize={24}
              /> */}
              <IconArrow/>
            </div>
            <div className='flex flex-col items-start justify-start'>
              <div className='text-[12px] opacity-40'>APY</div>
              <LSpan
                className='text-green text-[20px] font-bold'
                isLoading={isLoading}
                text={`${apy}%`}
                textSize={24}
              />
            </div>
          </div>
        </div>
        <Conditional if={!isStakingPoolOverfilled}>
          <div className='flex flex-wrap justify-center gap-1 text-[12px] leading-[14.52px]'>
            <div>
              <span>
                Stake now to secure your spot.
              </span>
            </div>
            <div className='flex gap-1'>
              <span>
                {' Only '}
              </span>
              <LSpan
                isLoading={isLoading}
                text={capacityLeft.toString()}
                textSize={12}
              />
              <span> SOL capacity left!</span>
            </div>
          </div>
        </Conditional>
        <Conditional if={isStakingPoolOverfilled}>
          <div className='justify-center gap-1 text-[12px] leading-[14.52px]'>
            <span>
              The staking pool has reached full capacity! Submit a ticket on our
              {' '}
            </span>
            <a className='text-gradient' href='https://discord.gg/lavarage' target='_blank'>
              Discord #Support channel
            </a>
            <span>
              {' '}
              to request a spot and get updates on future availability.
            </span>
          </div>
        </Conditional>
      </section>
    </>
  )
}
