import { API_HOST } from '@/app/app.config'
import { formatLamportsToSol, getPda } from '@/utils'
import { type AnchorProvider, Program } from '@coral-xyz/anchor'
import { bs58 } from '@coral-xyz/anchor/dist/cjs/utils/bytes'
import { StakingIdl } from '@lavarage/idls'
import { MintLayout, TokenAccountNotFoundError, getAccount, getAssociatedTokenAddress } from '@solana/spl-token'
import {
  type ConfirmedSignatureInfo,
  LAMPORTS_PER_SOL,
  type PartiallyDecodedInstruction,
  PublicKey,
  SystemProgram,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js'
import axios from 'axios'
import BigNumber from 'bignumber.js'

type UnstakedAccountsDeserialized = {
  publicKey: string
  depositor: string
  sourceTokenQuantity: BigNumber
  targetTokenQuantity: BigNumber
  unstakeTimestamp: number
  timestampClaim: number
}

export class StendingService {
  program: Program<typeof StakingIdl>
  unstakedAccounts: any[] = []
  stakeAccounts: any[] = []
  pnl?: { pnl: number; lastUpdate: number; hist: number }
  baseUrl: string = API_HOST
  private LST_MINT_ADDRESS = '1stqQC3rTGhCwsXsXqF8qEwz7LQ2euK858V7adB7gaQ'

  constructor(provider: AnchorProvider, programId: PublicKey) {
    this.program = new Program(StakingIdl, programId, provider)
  }

  async getVaultBalance() {
    const { data } = await axios.get(`${this.baseUrl}/staking-oracle-sign?wallet=${this.program.provider.publicKey}&type=vaultBalance`)
    return {
      total: BigNumber(data.vaultBalance.total).dividedBy(LAMPORTS_PER_SOL),
      delegated: BigNumber(data.vaultBalance.delegated).dividedBy(LAMPORTS_PER_SOL),
      deployed: BigNumber(data.vaultBalance.deployed).dividedBy(LAMPORTS_PER_SOL),
      nav: data.vaultBalance.nav,
      idle: data.vaultBalance.idle,
      openedPositions: data.vaultBalance.openedPositions,
      multisig: data.vaultBalance.multisig,
    }
  }

  async getCurrentPnl() {
    return {
      pnl: 0,
      lastUpdate: 0,
      hist: 0,
    }

    const data = getPda([Buffer.from('data')], this.program.programId.toBase58())

    const hist = await this.program.account.dataAccount.fetch(data)
    const txl = await this.program.provider.connection.getSignaturesForAddress(data)
    const txd = await this.program.provider.connection.getParsedTransactions(
      txl.map(t => t.signature),
      { maxSupportedTransactionVersion: 0 },
    )

    if (txd === null) {
      throw new Error('Failed to fetch the transactions')
    }

    let lastUpdate = 0

    txd.sort((a, b) => (b?.blockTime ?? 0) - (a?.blockTime ?? 0))

    for (const tx of txd) {
      try {
        if (tx === null) {
          throw new Error('Failed to fetch the transactions')
        }
        const instructions = tx.transaction.message.instructions

        if (instructions.length > 0) {
          const instructionData = (instructions[0] as PartiallyDecodedInstruction).data

          if (!instructionData) continue

          const decodedData = bs58.decode(instructionData)
          const dataView = new DataView(decodedData.buffer)

          if (dataView.getBigUint64(0, true) === BigInt(1589461866123320)) {
            lastUpdate = tx.blockTime ?? 0
            break
          }
        }
      }
      catch (e) {
        console.error('Error processing transaction', e)
      }
    }
    this.pnl = {
      pnl: 0,
      lastUpdate,
      hist: 0,
    }

    return this.pnl
  }

  async getUserLstSolBalance() {
    if (this.program.provider.publicKey === undefined) {
      throw new Error('Wallet not connected')
    }
    const userPublicKey = new PublicKey(this.program.provider.publicKey)
    const tokenMintPublicKey = new PublicKey(this.LST_MINT_ADDRESS)

    const associatedTokenAddress = await getAssociatedTokenAddress(tokenMintPublicKey, userPublicKey)
    try {
      const tokenAccount = await getAccount(this.program.provider.connection, associatedTokenAddress)
      return BigNumber(tokenAccount.amount.toString()).toNumber()
    }
    catch (e) {
      if (e instanceof TokenAccountNotFoundError) {
        return 0
      }
      else throw e
    }
  }

  async getLstSolSupply() {
    const mintPublicKey = new PublicKey(this.LST_MINT_ADDRESS)
    const mintAccountInfo = await this.program.provider.connection.getAccountInfo(mintPublicKey)
    if (!mintAccountInfo) {
      throw new Error('Failed to find mint address')
    }

    const mintData = MintLayout.decode(mintAccountInfo.data)
    return BigNumber(mintData.supply.toString())
  }

  async getSolReceivable(unstakedLstSol: string | number | BigNumber) {
    const vaultBalance = await this.getVaultBalance()
    const lstSolSupply = await this.getLstSolSupply()
    const receivable = BigNumber(unstakedLstSol).multipliedBy(BigNumber(vaultBalance.total).dividedBy(lstSolSupply))
    return receivable.toString()
  }

  async getLstSolReceivable(stakedSol: string | number | BigNumber) {
    const vaultBalance = await this.getVaultBalance()
    const lstSolSupply = await this.getLstSolSupply()
    return BigNumber(stakedSol).dividedBy(BigNumber(vaultBalance.total).dividedBy(lstSolSupply))
  }

  async getStakeAccounts() {
    if (this.stakeAccounts.length) return this.stakeAccounts

    const stakeAccounts = await this.program.account.dataAccount.all()
    const stakeAccountsDeserealized = stakeAccounts.map(account => {
      return {
        publicKey: account.publicKey.toString(),
        dailyNav: account.account.dailyNav.toString(),
        borrowedAmountAdjustment: account.account.borrowedAmountAdjustment.toString(),
        mint: account.account.mint.toString(),
      }
    })
    this.stakeAccounts = stakeAccountsDeserealized
    return stakeAccountsDeserealized
  }

  async getUnstakeAccounts(refresh = false): Promise<UnstakedAccountsDeserialized[]> {
    if (this.unstakedAccounts.length && !refresh) return this.unstakedAccounts

    const unstakedAccounts = await this.program.account.unstakeAccount.all([
      {
        memcmp: {
          offset: 24,
          bytes: this.program.provider.publicKey!.toBase58(),
        },
      },
    ])

    const getUnstakedAmount = async (txl: ConfirmedSignatureInfo[], ind: number) => {
      const txd = await this.program.provider.connection.getParsedTransaction(txl[ind].signature, { maxSupportedTransactionVersion: 0 })
      if (txd === null) {
        throw new Error('Failed to fetch the unstake accounts')
      }
      try {
        const instruction = txd.transaction.message.instructions.find(i => i.programId.toBase58() === this.program.programId.toBase58())
        if (instruction && 'data' in instruction) {
          const dv = new DataView(bs58.decode(instruction.data).buffer)
          const unstakeFrom = dv.getBigUint64(8, true)
          return unstakeFrom
        }
        else {
          throw new Error('Instruction does not contain data')
        }
      }
      catch (e) {
        return getUnstakedAmount(txl, ind + 1)
      }
    }

    const unstakedAccountsDeserialized = await Promise.all(unstakedAccounts.map(async account => {
      const txl = await this.program.provider.connection.getSignaturesForAddress(account.publicKey)
      const unstakeFrom = await getUnstakedAmount(txl, 0)
      const millisecondsToAdd = 120 * 60 * 60 * 1000 // 120 hours to add to the timestamp

      return {
        publicKey: account.publicKey.toString(),
        depositor: account.account.depositor.toString(),
        sourceTokenQuantity: formatLamportsToSol(Number(unstakeFrom.toString())),
        targetTokenQuantity: formatLamportsToSol(Number(account.account.pendingUnstakeSol.toString())),
        unstakeTimestamp: account.account.unstakeTimestamp.toNumber(),
        timestampClaim: account.account.unstakeTimestamp.toNumber() * 1000 + millisecondsToAdd,
      }
    }))

    this.unstakedAccounts = unstakedAccountsDeserialized
    return unstakedAccountsDeserialized
  }

  async stake(amount: number) {
    const amountLamports = BigNumber(amount).multipliedBy(1_000_000_000).toString()

    const { data } = await axios.get(`${this.baseUrl}/staking-oracle-sign?wallet=${this.program.provider.publicKey}&amount=${amountLamports}&type=stake`)
    const txData = Buffer.from(data.signedTx.data)
    return VersionedTransaction.deserialize(txData)
  }

  async unstake(amount: number) {
    const amountLamports = BigNumber(amount).multipliedBy(1_000_000_000).toString()
    const { data } = await axios.get(`${this.baseUrl}/staking-oracle-sign?wallet=${this.program.provider.publicKey}&amount=${amountLamports}&type=unstake&unstakingFee=0`)
    const txData = Buffer.from(data.signedTx.data)
    return [VersionedTransaction.deserialize(txData), data.unstakeAccount]
  }

  async claim(claimPk: string): Promise<VersionedTransaction> {
    const data = getPda([Buffer.from('data')], this.program.programId.toBase58())
    if (!this.program.provider.publicKey) {
      throw new Error('Wallet not connected')
    }
    const instruction = await this.program.methods
      .claim()
      .accountsStrict({
        data,
        unstakeAccount: claimPk,
        depositor: this.program.provider.publicKey,
        systemProgram: SystemProgram.programId,
      })
      .instruction()

    const { blockhash } = await this.program.provider.connection.getLatestBlockhash('finalized')

    const messageV0 = new TransactionMessage({
      payerKey: this.program.provider.publicKey,
      recentBlockhash: blockhash,
      instructions: [instruction],
    }).compileToV0Message()

    return new VersionedTransaction(messageV0)
  }
}
