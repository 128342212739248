import { type IAlert } from '@/app/stores/useAlertsStore'
import { Fragment, type HTMLAttributes } from 'react'
import { Alert } from './Alert'
import { For } from '@/app/components'

type Props = HTMLAttributes<HTMLDivElement> & {
  alerts: IAlert[]
}

export function Alerts({ alerts }: Props) {
  return (
    <For of={alerts}>
      {(alert, index) => (
        <Fragment key={(alert.reasonMessage ?? '') + index}>
          <Alert alert={alert} index={index}/>
        </Fragment>
      )}
    </For>
  )
}
