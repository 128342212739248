import type { HTMLAttributes, PropsWithChildren } from 'react'
import { Alerts, Footer, Header } from '../../components/index.js'
import { clsxm } from '../../helpers/clsxm.js'
import { useAlertsStore } from '../../stores/index.js'

type Props = HTMLAttributes<HTMLDivElement> & PropsWithChildren<{
  backgroundType?: 'default' | 'rewards' | 'not-found'
}>

export function Layout({
  children,
  className,
  backgroundType = 'default',
  ...props
}: Props) {
  const alerts = useAlertsStore(state => state.alerts)
  const backgroundClassName = getBackgroundClassName(backgroundType)

  return (
    <div className={clsxm(backgroundClassName, 'text-main font-inter text-base')}>
      <Alerts alerts={alerts}/>
      <div className='flex min-h-lvh flex-col'>
        <Header className='sticky top-0 z-10'/>
        <main {...props} className={clsxm('px-safe-or-[10px] flex w-full flex-1 flex-col items-center justify-start py-8', className)}>
          {children}
        </main>
        <Footer/>
      </div>
    </div>
  )
}

const getBackgroundClassName = (backgroundType: Props['backgroundType']) => {
  switch (backgroundType) {
    case 'rewards':
      return 'rewards-bg'
    case 'not-found':
      return 'not-found-bg'
    default:
      return 'main-bg'
  }
}
