export type RepayTipMode = 'maximum' | 'minRemainingLoanBalance' | 'minTransaction' | 'negative' | 'zero' | null

type GetRepayTipModeProps = {
  repayingNow: number
  maxRepaymentAmount: number
  remainingLoanBalance: number
  minRemainingLoanBalance: number
  minTransactionAmount: number
  isFullRepay: boolean
}

export const getRepayTipMode = ({
  repayingNow,
  maxRepaymentAmount,
  remainingLoanBalance,
  minRemainingLoanBalance,
  minTransactionAmount,
  isFullRepay,
}: GetRepayTipModeProps): RepayTipMode => {
  switch (true) {
    case isFullRepay:
      return null
    case repayingNow > maxRepaymentAmount:
      return 'maximum'
    case remainingLoanBalance < minRemainingLoanBalance:
      return 'minRemainingLoanBalance'
    case repayingNow < minTransactionAmount:
      return 'minTransaction'
    case repayingNow < 0:
      return 'negative'
    case repayingNow === 0:
      return 'zero'
    default:
      return null
  }
}
