interface TabsListProps {
  children: React.ReactNode
}

export const TradeTabsList = ({ children }: TabsListProps) => {
  return (
    // <div className='desk:top-[90px] sticky top-[60px] flex w-full' role='navigation'>
    <nav className='bg-main mx-auto flex h-[36px] items-center justify-between gap-[3px] rounded-full bg-opacity-5 p-[3px] text-sm' role='tablist'>
      {children}
    </nav>
    // </div>
  )
}
