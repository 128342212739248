import { Button } from '@/app/components'

export type CloseModalButtonMode = 'no-connection' | 'confirming' | 'confirmed' | 'restricted' | 'insufficient-funds' | 'sell' | 'repay' | 'partialRepay'

type CloseModalButtonProps = {
  closeModalButtonMode: CloseModalButtonMode
  sell: () => Promise<void>
  repay: () => Promise<void>
  partialRepay: () => Promise<void>
}

export const CloseModalButton = ({ closeModalButtonMode, sell, repay, partialRepay }: CloseModalButtonProps) => {
  function getLabelByMode(mode: CloseModalButtonMode) {
    switch (mode) {
      case 'no-connection':
        return 'No Connection'
      case 'confirming':
        return 'Confirming...'
      case 'confirmed':
        return 'Confirmed'
      case 'restricted':
        return 'Restricted'
      case 'insufficient-funds':
        return 'Insufficient Funds'
      case 'sell':
      case 'repay':
      case 'partialRepay':
        return 'Confirm'
      default:
        return 'Restricted'
    }
  }

  function getColorByMode(mode: CloseModalButtonMode) {
    switch (mode) {
      case 'no-connection':
      case 'confirming':
      case 'confirmed':
      case 'restricted':
      case 'insufficient-funds':
        return 'main'
      case 'sell':
      case 'repay':
      case 'partialRepay':
        return 'alt'
      default:
        return 'main'
    }
  }

  function getClickHandlerByMode(mode: CloseModalButtonMode) {
    switch (mode) {
      case 'sell':
        return sell()
      case 'repay':
        return repay()
      case 'partialRepay':
        return partialRepay()
      default:
        return null
    }
  }

  function isDisabledByMode(mode: CloseModalButtonMode) {
    return mode === 'no-connection' || mode === 'restricted' || mode === 'insufficient-funds' || mode === 'confirming' || mode === 'confirmed'
  }

  return (
    <Button
      color={getColorByMode(closeModalButtonMode)}
      disabled={isDisabledByMode(closeModalButtonMode)}
      size='s'
      onClick={() => getClickHandlerByMode(closeModalButtonMode)}
    >
      {getLabelByMode(closeModalButtonMode)}
    </Button>
  )
}
