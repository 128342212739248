import { type TakeProfitModalButtonMode } from '@/app/pages/positions/components'
import { MIN_TAKE_PROFIT_PERCENT } from '../../../app.config.js'

type GetTakeProfitModalButtonModeProps = {
  isOnline: boolean
  confirming: boolean
  isTakeProfitSet: boolean
  isRestricted?: boolean
  takeProfitPercent: number | null
}

export const getTakeProfitModalButtonMode = ({
  isOnline,
  confirming,
  isTakeProfitSet,
  isRestricted,
  takeProfitPercent,
}: GetTakeProfitModalButtonModeProps): TakeProfitModalButtonMode => {
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case confirming:
      return 'confirming'
    case isRestricted || (takeProfitPercent === null || takeProfitPercent < MIN_TAKE_PROFIT_PERCENT):
      return 'restricted'
    case !isTakeProfitSet:
      return 'createTakeProfit'
    case isTakeProfitSet:
      return 'removeTakeProfit'
    default:
      return 'restricted'
  }
}
