import { useLavarage } from '@/app/providers/LavarageProvider'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { SOL_ADDRESS, USDC_ADDRESS } from '../../../config.js'

export const programIdAddresses: Record<string, { programId: string; nodeWalletAddress: string }> = {
  [SOL_ADDRESS]: {
    programId: 'CRSeeBqjDnm3UPefJ9gxrtngTsnQRhEJiTA345Q83X3v',
    nodeWalletAddress: '6riP1W6R3qzUPWYwLGtXEC23aTqmyAEdDtXzhntJquAh',
  },
  [USDC_ADDRESS]: {
    programId: '1avaAUcjccXCjSZzwUvB2gS3DzkkieV2Mw8CjdN65uu',
    nodeWalletAddress: '6riP1W6R3qzUPWYwLGtXEC23aTqmyAEdDtXzhntJquAh',
  },
}

export const usePoolsQuery = () => {
  const lavarages = useLavarage()
  // const [quoteToken] = usePreferencesStore(useShallow(s => [s.quoteToken]))
  // const lavarage = quoteToken?.symbol === 'SOL' ? lavarages[0] : lavarages[1]
  // const prevProgramIdRef = useRef<string | null>(null)

  // useEffect(() => {
  //   if (quoteToken && programIdAddresses[quoteToken.address]) {
  //     const newProgramId = programIdAddresses[quoteToken.address].programId
  //     if (newProgramId !== prevProgramIdRef.current) {
  //       setProgramId(newProgramId)
  //       prevProgramIdRef.current = newProgramId
  //     }
  //   }
  // }, [quoteToken, setProgramId])
  const queryClient = useQueryClient()

  const {
    data: pools,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['pools'],
    queryFn: async () => {
      const [SolPools, NotSolPools] = await Promise.all([lavarages[0].getPools(), lavarages[1].getPools()])
      return [...SolPools, ...NotSolPools]
    },
    enabled: !!lavarages,
    initialData: [],
  })

  const refetchWithoutCache = () => queryClient.fetchQuery({
    queryKey: ['pools'],
    queryFn: async () => {
      const [SolPools, NotSolPools] = await Promise.all([lavarages[0].getPools(), lavarages[1].getPools()])
      return [...SolPools, ...NotSolPools]
    },
  })

  return { pools, isLoading, error, refetch, refetchWithoutCache }
}
