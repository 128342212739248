import { clsxm } from '@/app/helpers/clsxm'
import { CloseModalTabs } from '@/app/pages/positions/components/'

type CloseModalTabControlProps = {
  currentTab: CloseModalTabs
  setCurrentTab: (tab: CloseModalTabs) => void
  disabled: boolean
}

export const CloseModalTabControl = ({ currentTab, setCurrentTab, disabled }: CloseModalTabControlProps) => {
  return (
    <nav className='bg-vibrant mx-auto flex h-9 w-40 justify-between rounded-lg p-1 text-sm' role='tablist'>
      <div
        className={clsxm(
          'flex h-full w-1/2 items-center justify-center rounded-lg',
          {
            'bg-alt bg-opacity-10 text-alt transition': currentTab === CloseModalTabs.SELL,
          },
          {
            'cursor-pointer transition desk:hover:opacity-70': currentTab === CloseModalTabs.REPAY && !disabled,
          },
          { 'cursor-default': disabled },
        )}
        role='tab'
        onClick={() => {
          if (disabled) return
          setCurrentTab(CloseModalTabs.SELL)
        }}
      >
        {CloseModalTabs.SELL}
      </div>
      <div
        className={clsxm(
          'flex h-full w-1/2 flex-row items-center justify-center rounded-lg',
          {
            'bg-alt bg-opacity-10 text-alt transition': currentTab === CloseModalTabs.REPAY,
          },
          {
            'cursor-pointer transition desk:hover:opacity-70': currentTab === CloseModalTabs.SELL && !disabled,
          },
          { 'cursor-default': disabled },
        )}
        role='tab'
        onClick={() => {
          if (disabled) return
          setCurrentTab(CloseModalTabs.REPAY)
        }}
      >
        <div>{CloseModalTabs.REPAY}</div>
      </div>
    </nav>
  )
}
