import { usePreferencesStore } from '@/app/stores'
import { formatSol } from '@/utils/formatters'
import { memo, useState, type HTMLAttributes } from 'react'
import { IconDown, IconUp } from '../../../../assets/index.js'
import { Conditional } from '../../../components/Conditional.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { clsxm } from '../../../helpers/clsxm.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  borrowedAmount: number
  positionSize: number
  entryPrice: number | null
  liquidationPrice: number
  isLoading: boolean
  hasLavaRockNFT: boolean
  localFormatCurrency: (val: number, decimals?: number) => string | null
}

export const LoanSummary = memo(({
  borrowedAmount,
  positionSize,
  entryPrice,
  liquidationPrice,
  isLoading,
  hasLavaRockNFT,
  localFormatCurrency,
  ...props
}: Props) => {
  const baseTokenLong = usePreferencesStore(state => state.baseTokenLong)
  const [isExpanded, setIsExpanded] = useState(false)
  const onToggleDetails = () => setIsExpanded(!isExpanded)
  return (
    <div
      {...props}
      className={clsxm(
        'mx-9 flex flex-col  p-3',
        { 'border-main border border-opacity-5 rounded-lg': isExpanded },
      )}
    >
      <button className='mb-[10px] flex items-center justify-center text-xs opacity-40 transition' onClick={onToggleDetails}>
        <span>{isExpanded ? 'LOAN SUMMARY' : 'SHOW LOAN SUMMARY'}</span>
        <div className='my-auto ml-[5px]'>{isExpanded ? <IconUp height={20}/> : <IconDown height={20}/>}</div>
      </button>
      <Conditional if={isExpanded}>
        <div className='flex flex-col gap-[5px] text-sm'>
          <LabelledValue
            isLoading={isLoading}
            label='Borrowed Amount'
            value={localFormatCurrency(borrowedAmount)}
          />
          <LabelledValue
            hintInternalComponent={
              <>
                <Conditional if={hasLavaRockNFT}>
                  <span>Fee reduced for </span>
                  <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                  <span> holder!</span>
                  <p className='mb-5'/>
                </Conditional>
                <Conditional if={!hasLavaRockNFT}>
                  <span>Own a </span>
                  <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                  <span> to reduce your fees!</span>
                  <p className='mb-5'/>
                </Conditional>
                <span>More details on </span>
                <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
              </>
            }
            isLoading={isLoading}
            label='Position Size'
            value={localFormatCurrency(positionSize)}
          />
          <LabelledValue
            isLoading={isLoading}
            label='Daily Interest'
            labelHint='Interest is auto-collected from collateral every 7 days if the accrued amount exceeds 0.1 SOL or equivalent.'
            value={`${formatSol(baseTokenLong?.dailyInterest || 0)}%`}
          />
          <LabelledValue
            isLoading={isLoading || entryPrice === null}
            label='Entry Price'
            value={entryPrice !== null ? localFormatCurrency(entryPrice, 6) : null}
          />
          <LabelledValue
            isLoading={isLoading}
            label='Liquidation Price'
            labelHint="Lenders may liquidate the position if the asset's price falls to or below a 90% LTV threshold."
            value={localFormatCurrency(liquidationPrice, 6)}
          />
        </div>
      </Conditional>
    </div>
  )
})
