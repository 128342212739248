import { Conditional, TokenImage } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { type TokenInfo } from '@/services/types'
import { IconCoingecko, IconDown } from '../../../assets/index.js'
import { Skeleton } from '../Skeleton.js'

type CoinButtonProps = {
  token: TokenInfo
  disabled?: boolean
  onClick?: () => void
  className?: string
  loading?: boolean
  hasDropdown?: boolean
  isSwapList?: boolean
}

export const CoinButton = ({ isSwapList, token, onClick, className, loading, hasDropdown }: CoinButtonProps) => {
  return (
    <>
      <Conditional if={loading}>
        <div
          className={clsxm('desk:hover:bg-opacity-10 flex h-[44px] select-none items-center justify-between gap-[5px] rounded-lg bg-opacity-5 px-[8px] transition')}
        >
          <Skeleton/>
        </div>
      </Conditional>
      <Conditional if={!loading}>
        <button
          className={clsxm(
            'text-main desk:hover:bg-opacity-10 relative flex h-[44px] select-none items-center justify-between gap-[5px] rounded-lg bg-opacity-5 px-[8px] transition',
            {
              'bg-alt': !isSwapList,
              'bg-main': isSwapList,
            },
            className,
          )}
          onClick={onClick}
        >
          <Conditional if={token?.isTopCoin}>
            <div
              className='absolute bottom-[-7px] right-[-6px] flex items-center justify-center'
            >
              <IconCoingecko/>
            </div>
          </Conditional>
          <TokenImage altName={token?.name} logoURI={token?.logoURI} tag={isSwapList ? undefined : token?.isNewCoin ? 'NEW' : token?.isTopCoin ? 'HOT' : undefined}/>
          <span
            className='select-none text-sm'
          >
            {token?.symbol?.toUpperCase()}
          </span>
          <Conditional if={hasDropdown}>
            <IconDown/>
          </Conditional>
        </button>
      </Conditional>
    </>
  )
}
