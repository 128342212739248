import { MIN_REMAINING_LOAN_BALANCE, MIN_TRANSACTION_AMOUNT } from '../../../../config.js'
import { formatSol } from '../../../../utils/index.js'
import { Conditional } from '../../../components/Conditional.js'
import { Tip } from '../../../components/Tip.js'
import { type QuoteTokenSymbol } from '../../main/MainPage.js'
import { type RepayTipMode } from '../utils/getRepayTipMode.js'

type RepayTipProps = {
  quoteSymbol: string
  repayingNow: number
  maxRepaymentAmount: number
  remainingLoanBalance: number
  minRemainingLoanBalance: number
  repayTipMode: RepayTipMode
}

export function RepayTip({ quoteSymbol, repayingNow, maxRepaymentAmount, remainingLoanBalance, minRemainingLoanBalance, repayTipMode }: RepayTipProps) {
  return (
    <Conditional if={repayTipMode !== null}>
      <Tip isAlt>
        <span className='text-alt text-left '>
          <Conditional if={repayTipMode === 'maximum'}>
            <span>
              {`Maximum repayment amount is ${formatSol(maxRepaymentAmount)} ${quoteSymbol}!`}
            </span>
          </Conditional>
          <Conditional if={repayTipMode === 'minRemainingLoanBalance'}>
            <span>
              {`Partial repayment is unavailable for amounts that would reduce the loan balance below ${MIN_REMAINING_LOAN_BALANCE[quoteSymbol as QuoteTokenSymbol]} ${quoteSymbol}! Why not fully close your position instead?`}
            </span>
          </Conditional>
          <Conditional if={repayTipMode === 'minTransaction'}>
            <span>
              {`Your repayment amount of ${formatSol(repayingNow)} ${quoteSymbol} is below the minimum allowed transaction limit of ${MIN_TRANSACTION_AMOUNT[quoteSymbol as QuoteTokenSymbol]} ${quoteSymbol}!`}
            </span>
          </Conditional>
          <Conditional if={repayTipMode === 'negative'}>
            <span>
              Repayment amount cannot be negative!
            </span>
          </Conditional>
          <Conditional if={repayTipMode === 'zero'}>
            <span>
              Repayment amount cannot be zero!
            </span>
          </Conditional>
        </span>
      </Tip>
    </Conditional>
  )
}
