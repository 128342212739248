import { FALLBACK_RPC, SOLANA_RPC, STAKING_PROGRAM_ID } from '@/config'
import { StendingService } from '@/services'
import { AnchorProvider } from '@coral-xyz/anchor'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { createContext, useContext, useMemo } from 'react'
import FallbackConnection from 'solana-fallback-connection'

const programId = new PublicKey(STAKING_PROGRAM_ID)
const StendingContext = createContext<StendingService | undefined>(undefined)

const StendingProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useWallet()

  const connection = useMemo(() => new FallbackConnection([SOLANA_RPC, FALLBACK_RPC]), [])

  const stendingService = useMemo(
    () => (connection ? new StendingService(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()), programId) : undefined),
    [wallet.publicKey, connection],
  )

  return <StendingContext.Provider value={stendingService}>{children}</StendingContext.Provider>
}

const useStendingService = () => useContext(StendingContext)

export { StendingProvider, useStendingService }
