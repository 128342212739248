import { Conditional } from '@/app/components'
import type { HTMLAttributes } from 'react'
import { Skeleton } from './Skeleton.js'

type Props = HTMLAttributes<HTMLSpanElement> & {
  text: string | null | undefined
  unit?: string | null
  isUnitLeft?: boolean
  className?: string
  skeletonClassName?: string
  isLoading?: boolean
  isInvalid?: boolean
  h?: number
}

export function LSpan({
  text,
  unit = '',
  isUnitLeft,
  isLoading,
  isInvalid,
  className,
  skeletonClassName,
  h,
  ...props
}: Props) {
  const isInvalidText = (typeof text !== 'string' && text !== null) || text === '--' || isInvalid

  return (
    <>
      <Conditional if={isLoading}>
        <Skeleton className={skeletonClassName}/>
      </Conditional>
      <Conditional if={isInvalidText && !isLoading}>
        <span {...props} className={className}>--</span>
      </Conditional>
      <Conditional if={text && !isInvalidText && !isLoading}>
        <span {...props} className={className}>{isUnitLeft ? `${unit} ${text}` : `${text} ${unit}`}</span>
      </Conditional>
    </>
  )
}
