import { Modal } from '@/app/components/Modal'
import { IconX } from '@/assets'
import { toPng } from 'html-to-image'
import { forwardRef, useEffect, useRef, useState } from 'react'
import {
  TwitterShareButton,
} from 'react-share'
import { IS_PROD } from '../../../app.config.js'
import { Button, Conditional } from '../../../components/index.js'
import { TwitterShare, type TwitterShareProps } from './TwitterShare.js'
export const ShareModalComponent = forwardRef<HTMLDivElement, TwitterShareProps>(({
  position,
  prices,
  timezone,
  onExit,
}, ref) => {
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const [isCopied, setIsCopied] = useState(false)
  const shareRef = useRef<HTMLDivElement>(null)
  const [isPreviewVisible, setIsPreviewVisible] = useState(false)

  useEffect(() => {
    const interval = setInterval(async () => {
      if (shareRef.current) {
        try {
          const dataUrl = await toPng(shareRef.current)
          setPreviewImage(dataUrl)
        }
        catch (error) {
          console.error('Failed to generate preview image:', error)
        }
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  const onCopyClick = async () => {
    if (shareRef.current && previewImage) {
      try {
        const blob = await (await fetch(previewImage)).blob()
        await navigator.clipboard.write([
          new ClipboardItem({ 'image/png': blob }),
        ])
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 2000)
      }
      catch (error) {
        console.error('Failed to copy image:', error)
        alert('Failed to copy image to clipboard.')
      }
    }
  }
  const handleClickPreview = () => {
    setIsPreviewVisible(true)
  }

  const downloadImage = async () => {
    if (previewImage) {
      const blob = await (await fetch(previewImage)).blob()
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'share-image.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
    else {
      alert('Image preview is not available for download.')
    }
  }
  const isClipboardSupport = 'clipboard' in navigator && typeof ClipboardItem !== 'undefined'

  const leverage = position.initialLeverage.toNumber().toFixed(1)
  // const baseSymbol = position.pool?.baseCurrency?.symbol
  // const entryPrice = position.entryPrice.toNumber()
  const { pool: { baseCurrency, quoteCurrency } } = position
  if (!baseCurrency || !quoteCurrency) return null
  // const collateralPrice = prices?.[baseCurrency.address]?.price
  // const quotePriceInUSD = prices?.[quoteCurrency?.address]?.price
  // const { hasLavaRockNFT } = useNftAccess()
  // const slippage = 0.5
  // const confirmedSellInSol = useConfirmedSell(position, hasLavaRockNFT, Number(slippage))
  // const {
  // currentPrice,
  // roi,
  // finalRoi,
  // startDate,
  // } = calculatePositionMetrics(position, quotePriceInUSD, collateralPrice, timezone, confirmedSellInSol)
  // const price = position.status === 'active' ? formatSol(currentPrice) : formatSol(position.price.toNumber())

  // const bnRoi = position.status === 'active' ? roi : finalRoi
  // const dispRoi = formatSol(bnRoi.toNumber(), bnRoi.isLessThan(10) ? 1 : 0)
  // const isLessThenMin = bnRoi.isLessThan(1)
  // const isBuyMode = isLessThenMin || position.status === 'repaid' || position.status === 'liquidated'
  // const formattedDate = formatTimestampToGMT(position.openDate.getTime() / 1000, timezone)
  // const { publicKey } = useWallet()
  // const referralCode = publicKey?.toBase58()
  const getPrettySymbol = (symbol: string | undefined) => {
    if (!symbol) {
      return ''
    }
    if (symbol.length >= 7) {
      return `#${symbol}`
    }
    if (symbol[0] === '$') {
      return symbol
    }
    else {
      return `$${symbol}`
    }
  }

  return (
    <Modal className='z-40'>
      <div ref={ref} className='bg-background modal-box max-w-[680px] p-[10px] pb-5 text-sm'>
        <div className='relative flex flex-col gap-5'>
          <div className='mb-[10px] flex items-center justify-between px-[10px] py-[8px] text-base'>
            <button className='btn-icon flex-1'>
              <IconX onClick={onExit}/>
            </button>
            <span className='grow font-bold'>Share your Position</span>
            <Conditional if={IS_PROD}>
              <span className='flex flex-1 justify-end'/>
            </Conditional>
            <Conditional if={!IS_PROD}>
              <button className='flex flex-1 justify-end' onClick={handleClickPreview}>Preview</button>
            </Conditional>
          </div>
          {previewImage ? (
            <img
              alt='Preview'
              className='w-full max-w-full rounded-lg'
              src={previewImage}
            />
          ) : (
            <div className='flex h-[371px] items-center justify-center text-center text-white'>Generating preview...</div>
          )}

          <section className='bg-alt/10 mx-[41px] flex justify-center gap-[6px] rounded-lg p-[10px]'>
            <Conditional if={isClipboardSupport}>
              <Button
                color='gradient'
                size='l'
                onClick={onCopyClick}
              >
                {isCopied ? 'Copied' : 'Copy Image to Clipboard'}
              </Button>
            </Conditional>
            <Button
              color='gradient'
              size='l'
              onClick={downloadImage}
            >
              Download Image
            </Button>
            <Button
              color='gradient'
              size='l'
            >
              <TwitterShareButton
                className='btn-twitter'
                title={`APE’d ${getPrettySymbol(position.pool?.baseCurrency?.symbol)} at ${leverage}x leverage on app.lavarage.xyz 🚀 @lavaragexyz #solana

CA: ${position.pool?.baseCurrency?.address}`}
                // 👉`}
                // #Solana #Lavarage #DegenWithLavarage #${position.pool?.baseCurrency?.symbol}
                // url={`https://app.lavarage.xyz/?ref=${referralCode}`}
                url=' '
              >
                Share on Twitter
              </TwitterShareButton>
            </Button>
          </section>

          <div className='absolute left-[-10000px] top-[-10000px]'>
            <TwitterShare ref={shareRef} position={position} prices={prices} timezone={timezone} onExit={onExit}/>
          </div>
        </div>
      </div>
      {isPreviewVisible && (
        <Modal className='z-40'>
          <TwitterShare ref={shareRef} position={position} prices={prices} timezone={timezone} onExit={onExit}/>
        </Modal>
      )}
    </Modal>
  )
})

ShareModalComponent.displayName = 'ShareModal'

export const ShareModal = ShareModalComponent
