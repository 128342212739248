import { formatSol } from '@/utils/formatters'
import { type PriceResponse } from '@lavarage/entities'
import { type TokenInfo } from '../../services/types.js'
import { isValidNumber } from '../isValidNumber'

export const formatCurrency = (value: number, toUSDC: PriceResponse['data'], isUsdModeEnabled: boolean, decimalPoints: number, quoteToken: TokenInfo) => {
  if (!isValidNumber(value)) return '--'
  const unit = quoteToken?.symbol
  if (!isUsdModeEnabled) {
    return `${formatSol(value, decimalPoints)} ${unit}`
  }
  const valueInUSD = value * toUSDC?.[quoteToken?.address]?.price
  const formatValueInUSD = formatSol(valueInUSD, decimalPoints)
  return formatValueInUSD === '0'
    ? `$${formatSol(valueInUSD)}`
    : valueInUSD < 0
      ? `-$${formatToTwoDecimalPoints(formatValueInUSD).slice(1)}`
      : `$${formatToTwoDecimalPoints(formatValueInUSD)}`

  function formatToTwoDecimalPoints(formattedValue: string): string {
    const parts = formattedValue.split('.')
    const decimal = parts[1]

    if (decimal?.length > 2) {
      return formattedValue
    }
    if (decimal?.length === 2) {
      return formattedValue
    }
    if (decimal?.length === 1) {
      return `${formattedValue}0`
    }
    return `${formattedValue}.00`
  }
}
