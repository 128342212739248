import { IconHintAsc, IconHintRed } from '@/assets/index.js'
import type { PropsWithChildren } from 'react'

type TipProps = PropsWithChildren<{
  text?: string
  isAlt?: boolean
}>

export function Tip({ text, children, isAlt = false }: TipProps) {
  return (
    <>
      <div className='bg-purple mx-auto flex max-w-[400px] items-center justify-center gap-[5px] rounded-lg bg-opacity-[0.16] px-[10px] py-2 text-xs'>
        <div className={isAlt ? '' : 'mb-auto'}>
          {isAlt ? <IconHintRed/> : <IconHintAsc/>}
        </div>
        {text && <span>{text}</span>}
        {children}
      </div>
    </>
  )
}
