import { NotifiContextProvider } from '@notifi-network/notifi-react'
import '@notifi-network/notifi-react-card/dist/index.css'
import { MemoProgramHardwareLoginPlugin } from '@notifi-network/notifi-solana-hw-login'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { useMemo } from 'react'
import { NotifiElement } from './NotifiElement.js'

export const Notifi: React.FC = () => {
  const { connection } = useConnection()
  const { wallet, sendTransaction, signMessage } = useWallet()
  const adapter = wallet?.adapter
  const publicKey = adapter?.publicKey?.toBase58() ?? null

  const hwLoginPlugin = useMemo(() => {
    return new MemoProgramHardwareLoginPlugin({
      walletPublicKey: publicKey ?? '',
      connection,
      sendTransaction,
    })
  }, [publicKey, connection, sendTransaction])

  if (publicKey === null || signMessage === undefined) {
    return null
  }

  return (
    <>
      <NotifiContextProvider
        cardId='0191ebdd070f7671ac2c8f7d96dd4f7e'
        env='Production'
        hardwareLoginPlugin={hwLoginPlugin}
        signMessage={signMessage}
        tenantId='1vi7ntf1mniy88fgfdu7'
        walletBlockchain='SOLANA'
        walletPublicKey={publicKey}
      >
        <NotifiElement/>
      </NotifiContextProvider>
    </>
  )
}
