import { Conditional, FocusControlledNumericInput } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { usePreferencesStore } from '@/app/stores'
import { IconSlippage, IconWhiteMinus, IconWhitePlus, IconX } from '@/assets/svgs'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Modal } from './Modal'

type SlippageModalProps = { disabled?: boolean }
export const SlippageModal = ({ disabled }: SlippageModalProps) => {
  const [slippage, setSlippage] = usePreferencesStore(useShallow(state => [state.slippage, state.setSlippage]))
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const slippageMin = '0.5'
  const slippageMax = '40'
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (isModalVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        inputRef?.current?.blur()
        setIsModalVisible(false)
      }
    },
    [isModalVisible],
  )
  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside as EventListener)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener)
    }
  }, [isModalVisible, handleClickOutside])

  const toggleModal = () => {
    if (disabled) return

    setIsModalVisible(!isModalVisible)
  }

  useEffect(() => {
    if (isModalVisible) {
      const targetElement = wrapperRef.current
      if (targetElement) disableBodyScroll(targetElement)
      return () => {
        if (targetElement) enableBodyScroll(targetElement)
      }
    }
  }, [isModalVisible])

  return (
    <div className='flex justify-end'>
      <button className={`${disabled ? 'cursor-default' : 'btn-icon'} flex items-center justify-end self-center`} onClick={toggleModal}>
        <IconSlippage/>
        <span className='ml-[3px] text-sm'>
          {slippage}
          %
        </span>
      </button>

      <Conditional if={isModalVisible}>
        <Modal className='overflow-hidden'>
          <div ref={wrapperRef} className='bg-vibrant w-10/12 max-w-[380px] rounded-lg p-[10px] pb-5'>
            <div className='mb-[10px] flex justify-between'>
              <span className='py-[2.5px] text-base'>Slippage Tolerance</span>
              <button className='btn-icon' onClick={toggleModal}>
                <IconX/>
              </button>
            </div>
            <div className='text-main flex justify-between gap-[10px] text-sm'>
              <button
                className={clsxm(
                  'flex h-11 w-[56px] cursor-pointer',
                  'items-center justify-center',
                  'bg-main desk:hover:bg-opacity-20 rounded-lg bg-opacity-10 transition',
                )}
                onClick={() => setSlippage(slippageMin)}
              >
                {slippageMin}
                %
              </button>
              <div className='flex flex-1 justify-between text-sm'>
                <button
                  className={clsxm(
                    'flex h-11 w-11 cursor-pointer items-center justify-center',
                    'bg-main rounded-l-lg bg-opacity-10',

                    {
                      'transition desk:hover:bg-opacity-20': parseFloat(slippage) > Number(slippageMin),
                    },
                    {
                      'cursor-default': parseFloat(slippage) === Number(slippageMin),
                    },
                  )}
                  onClick={() => setSlippage((Math.round(10 * Math.max(Number(slippageMin), parseFloat(slippage) - 0.1)) / 10).toString())}
                >
                  <div
                    className={clsxm({
                      'opacity-40': parseFloat(slippage) === Number(slippageMin),
                    })}
                  >
                    <IconWhiteMinus/>
                  </div>
                </button>
                <label className='bg-main/10 flex flex-1 cursor-text items-center justify-center' htmlFor='slippageInput'>
                  <FocusControlledNumericInput
                    isSlippage
                    id='slippageInput'
                    inputRef={inputRef}
                    MAX_VALUE={Number(slippageMax)}
                    MIN_VALUE={Number(slippageMin)}
                    setValue={setSlippage}
                    value={slippage}
                  />
                </label>
                <button
                  className={clsxm(
                    'flex h-11 w-11 cursor-pointer items-center justify-center',
                    'bg-main rounded-r-lg bg-opacity-10',
                    {
                      'transition desk:hover:bg-opacity-20': parseFloat(slippage) < Number(slippageMax),
                    },
                    {
                      'cursor-default': parseFloat(slippage) === Number(slippageMax),
                    },
                  )}
                  onClick={() => setSlippage((Math.round(10 * Math.min(Number(slippageMax), parseFloat(slippage) + 0.1)) / 10).toString())}
                >
                  <div
                    className={clsxm({
                      'opacity-40': parseFloat(slippage) === Number(slippageMax),
                    })}
                  >
                    <IconWhitePlus/>
                  </div>
                </button>
              </div>
              <button
                className={clsxm(
                  'flex h-11 w-[56px] cursor-pointer',
                  'items-center justify-center',
                  'bg-main desk:hover:bg-opacity-20 rounded-lg bg-opacity-10 transition',
                )}
                onClick={() => setSlippage(slippageMax)}
              >
                {slippageMax}
                %
              </button>
            </div>
          </div>
        </Modal>
      </Conditional>
    </div>
  )
}
