import { type TokenInfo } from '@/services'
import { type Pool } from '@lavarage/entities'

export const getRelatedPool = (token: TokenInfo, pools: Pool[], quoteCurrency: string) => {
  const relatedPools = pools.filter(pool => pool.baseCurrency.address === token?.address && pool.maxBorrow.gt(0) && (pool.quoteCurrency.address === quoteCurrency))
  // console.log('relatedPools', relatedPools)
  const relatedPoolWithBiggestLiquidity = relatedPools.sort((a, b) => {
    const bTotalFunds = Number(b?.nodeWallet?.totalFunds?.toString())
    const aTotalFunds = Number(a?.nodeWallet?.totalFunds?.toString())
    return bTotalFunds - aTotalFunds
  })[0]
  // console.log('relatedPoolWithBiggestLiquidity', relatedPoolWithBiggestLiquidity)
  return relatedPoolWithBiggestLiquidity
}
