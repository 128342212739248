type SkeletonProps = {
  className?: string
  h?: number
  w?: number
}
export function Skeleton({ className }: SkeletonProps) {
  // const size = `min-w-[${w}px] h-[${h}px] w-[${w}px]`
  return (

    <span
      className={`bg-main flex animate-pulse flex-wrap justify-center rounded-lg bg-gray-200 opacity-10 dark:bg-gray-700 ${className ?? 'h-[16px] w-[48px]'}`}
    />

  )
}
