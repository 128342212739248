interface TabsListProps {
  children: React.ReactNode
}

export const TabsList = ({ children }: TabsListProps) => {
  return (
    <div className='desk:top-[90px] sticky top-[60px] flex w-full' role='navigation'>
      <nav className='bg-vibrant mx-auto flex h-[44px] items-center justify-between rounded-full p-2 text-sm' role='tablist'>
        {children}
      </nav>
    </div>
  )
}
