import { type CloseModalButtonMode, CloseModalTabs } from '@/app/pages/positions/components'
import { type RepayTipMode } from './getRepayTipMode.js'

type GetCloseModalButtonModeProps = {
  isOnline: boolean
  currentTab: CloseModalTabs
  confirming: boolean
  isConfirmedPosition: boolean
  available: number | null
  borrowedAmount: number
  isRestricted?: boolean
  repayPercent?: number
  repayAmount: number
  repayTipMode?: RepayTipMode
}

export const getCloseModalButtonMode = ({
  isOnline,
  currentTab,
  confirming,
  isConfirmedPosition,
  available,
  borrowedAmount,
  isRestricted,
  repayPercent,
  repayAmount,
  repayTipMode,
}: GetCloseModalButtonModeProps): CloseModalButtonMode => {
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case confirming:
      return 'confirming'
    case isConfirmedPosition:
      return 'confirmed'
    case isRestricted || available === null:
      return 'restricted'
    case repayPercent === 100 && available !== null && borrowedAmount > available && currentTab === CloseModalTabs.REPAY:
    case repayPercent !== 100 && available !== null && repayAmount > available && currentTab === CloseModalTabs.REPAY:
      return 'insufficient-funds'
    case repayPercent !== 100 && available !== null && repayAmount && repayAmount <= available && !confirming && currentTab === CloseModalTabs.REPAY && repayTipMode === null:
      return 'partialRepay'
    case repayPercent === 100 && available !== null && borrowedAmount <= available && !confirming && currentTab === CloseModalTabs.REPAY:
      return 'repay'
    case !confirming && currentTab === CloseModalTabs.SELL:
      return 'sell'
    default:
      return 'restricted'
  }
}
