import { FALLBACK_RPC, PROGRAM_IDS, SOLANA_RPC } from '@/config'
import { LavarageService, LavarageV2Service } from '@/services'
import { AnchorProvider } from '@coral-xyz/anchor'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import React, { createContext, useContext, useMemo } from 'react'
import FallbackConnection from 'solana-fallback-connection'

type LavarageContextType = [LavarageService, LavarageV2Service]
const publicKeysFromProgramIds = PROGRAM_IDS.map(id => new PublicKey(id))

const LavarageContext = createContext<LavarageContextType | undefined>(undefined)

const LavarageProvider = ({ children }: { children: React.ReactNode }) => {
  const wallet = useWallet()

  const connection = useMemo(() => new FallbackConnection([SOLANA_RPC, FALLBACK_RPC]), [])

  const lavarages = useMemo(
    () => [new LavarageService(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()), publicKeysFromProgramIds[0]), new LavarageV2Service(new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions()), publicKeysFromProgramIds[1])] as LavarageContextType,
    [wallet.publicKey, connection],
  )
  // if (lavarages === undefined) {
  //   throw new Error('useLavarage must be used within a LavarageProvider')
  // }

  return <LavarageContext.Provider value={lavarages}>{children}</LavarageContext.Provider>
}

const useLavarage = () => {
  const context = useContext(LavarageContext)
  if (context === undefined) {
    throw new Error('useLavarage must be used within a LavarageProvider')
  }
  return context
}

export { LavarageProvider, useLavarage }
