import type { TradeModeType } from '../MainPage.js'

export const Chart = ({ baseToken, quoteToken, tradeMode }: { baseToken: string; quoteToken: string; tradeMode: TradeModeType }) => {
  return (
    <div className='desk:h-[620px] h-[500px] p-2'>
      <iframe
        allowFullScreen
        className='h-full rounded-2xl'
        src={`https://birdeye.so/tv-widget/${tradeMode === 'swap' ? quoteToken : baseToken}/${tradeMode === 'swap' ? baseToken : quoteToken}?chain=solana&viewMode=base%2Fquote&chartInterval=15&chartType=CANDLE&chartTimezone=Etc%2FUTC&chartLeftToolbar=show&theme=dark`}
        style={{ border: 'none' }}
        width='100%'
      />
    </div>
  )
}
