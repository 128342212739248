import { type NodeWallet } from '@lavarage/entities'
import { API_HOST } from '../app/app.config.js'
import { BaseService } from './BaseService'

export class NodeWalletService extends BaseService {
  private nodeWallets: NodeWallet[] = []
  private cacheTimestamp = 0

  async getNodeWallets(): Promise<NodeWallet[]> {
    if (Date.now() - this.cacheTimestamp < 1000 * 30) {
      return this.nodeWallets
    }

    const nodeWallets = (await this.http.get(`${API_HOST}/node-wallets`)).data
    this.nodeWallets = nodeWallets
    this.cacheTimestamp = Date.now()
    // console.log('nodeWallets', nodeWallets)
    return nodeWallets
  }
}

export const nodeWalletService = new NodeWalletService()
