export const getColorOfLTV = (val: number): string => {
  switch (true) {
    case val >= 90:
      return 'text-purple'
    case val >= 70 && val < 90:
      return 'text-yellow'
    case val >= 50 && val < 70:
      return 'text-main'
    default:
      return 'text-green'
  }
}
