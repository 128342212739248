import { IconAltHint, IconBirdeye, IconDown, IconShare, IconSolscan, IconUp } from '@/assets/svgs/index.js'
import { CRYPTO_DECIMAL_POINTS, LIQUIDATION_LTV } from '@/config.js'
import { type TokenInfo } from '@/services/types.js'
import { formatPercent, formatSol } from '@/utils/formatters/index.js'
import type { Position } from '@lavarage/entities'
import clsx from 'clsx'
import { type HTMLAttributes } from 'react'
import { Button, Conditional, TokenImage } from '../../../components/index.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { capitalize, clsxm, getTextColorByString } from '../../../helpers/index.js'
import { useConfirmedSell } from '../../../hooks/useConfirmedSell.js'
import { useNftAccess } from '../../../hooks/useNftAccess.js'
// import { usePriceContext } from '../../../providers/PriceProvider.js'
import { IS_TAKE_PROFIT_ENABLED } from '../../../app.config.js'
import { usePreferencesStore } from '../../../stores/index.js'
import { calculatePositionMetrics, getPositionPnl, getPositionRoi, timeSince } from '../utils'
import { getColorOfLTV } from '../utils/getColorOfLTV.js'

const getStatusColorByPosition = ({ status }: Position) => {
  switch (status) {
    case 'recalled':
      return 'recalled'
    case 'liquidated':
      return 'liquidated'
    default:
      return 'active'
  }
}

type Props = HTMLAttributes<HTMLDivElement> & {
  isExpanded?: boolean
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  tokens?: TokenInfo[]
  onClose?: (position: Position) => void
  onShare?: (position: Position) => void
  onToggleDetails?: () => void
  onTakeProfit?: (position: Position) => void
}

export const PositionCard = ({
  className,
  isExpanded,
  position,
  prices,
  timezone,
  tokens,
  onClose,
  onShare,
  onToggleDetails,
  onTakeProfit,
  ...props
}: Props) => {
  const { pool: { baseCurrency, quoteCurrency } } = position
  if (!baseCurrency || !quoteCurrency) return null
  // const { priceSol, priceUsdc, solPriceLoading, solPriceError, usdcPriceLoading, usdcPriceError, refresh } = usePriceContext()
  const collateralPrice = prices?.[baseCurrency.address]?.price
  const quotePriceInUSD = prices?.[quoteCurrency?.address]?.price

  const { hasLavaRockNFT } = useNftAccess()
  const slippage = usePreferencesStore(state => state.slippage)
  const confirmedSellInSol = position.state === 'open' ? useConfirmedSell(position, hasLavaRockNFT, Number(slippage)) : undefined
  const {
    currentPrice,
    liquidationPrice,
    currentLTV,
    roi,
    finalRoi,
    priceChangePercentage,
    pnl,
    finalPnl,
    averageDailyInterest,
    closingChangePercentage,
    startDate,
    closeDate,
    sellFromPosition,
  } = calculatePositionMetrics(position, quotePriceInUSD, collateralPrice, timezone, confirmedSellInSol)

  const positionPnl = getPositionPnl({ position, pnl, finalPnl })
  const positionRoi = getPositionRoi({ position, roi, finalRoi })
  const positionSizeInSolForOpenPosition = confirmedSellInSol || sellFromPosition
  const displayedPositionSize = position.status === 'active' ? positionSizeInSolForOpenPosition : position.positionSize.toNumber()
  const dispLTV = position.status === 'active' ? `${formatSol(currentLTV.toNumber(), 0)}` : formatSol(position.borrowedAmount.div(position.positionSize).multipliedBy(100).toNumber(), 0)
  const baseSymbol = baseCurrency.symbol
  const quoteSymbol = quoteCurrency.symbol

  // if (position.publicKey === 'A25u3pCFDbYbmAkqBkq9CXhtidBsC4NCRQKWZNQnawzA') {
  //   console.log('prices', prices, 'quoteCurrency?.address', quoteCurrency?.address, 'collateralSize', position.collateralSize.toNumber())
  //   console.log('SolPos', {
  //     initialPositionSize: position.initialPositionSize.toNumber(),
  //     positionSize: position.positionSize.toNumber(),
  //     currentPrice,
  //     liquidationPrice,
  //     currentLTV: currentLTV.toNumber(),
  //     roi,
  //     finalRoi,
  //     priceChangePercentage,
  //     pnl,
  //     finalPnl,
  //     averageDailyInterest,
  //     closingChangePercentage,
  //     startDate,
  //     closeDate,
  //     sellFromPosition,
  //   }, 'position.closingPositionSize', position.initialPositionSize.toNumber(), 'collateralPrice', collateralPrice, 'collateralSize', position.collateralSize.toNumber())
  // }
  const isMobile = window.innerWidth < 768

  return (
    <div {...props} className={clsx(className, 'flex flex-col overflow-hidden rounded-lg text-sm')}>
      <div className='bg-main/10 flex justify-between px-[10px] py-[5px]'>
        <div className='flex flex-1 justify-start gap-[6px]'>
          <Conditional if={position.status === 'active' && IS_TAKE_PROFIT_ENABLED}>
            <IconSolscan className='my-auto mr-[10px] cursor-pointer' onClick={() => window.open(`https://solscan.io/account/${position?.publicKey}`, '_blank', 'noopener,noreferrer')}/>
            <Conditional if={!isMobile}>
              <IconShare className='my-auto mr-[10px] cursor-pointer' onClick={() => onShare?.(position)}/>
            </Conditional>
            <IconBirdeye className='my-auto mr-[6px] cursor-pointer' onClick={() => window.open(`https://birdeye.so/token/${position?.pool.baseCurrency?.address}?chain=solana`, '_blank', 'noopener,noreferrer')}/>
          </Conditional>
          <Conditional if={position.status === 'active' && !IS_TAKE_PROFIT_ENABLED}>
            <Button
              disabled
              color={getStatusColorByPosition(position)}
              size='s'
            >
              <p className='flex items-center'>
                {capitalize(position.status)}

              </p>
            </Button>
          </Conditional>
          <Conditional if={position.status === 'liquidated' || position.status === 'sold' || position.status === 'repaid'}>
            <Button
              disabled
              color={getStatusColorByPosition(position)}
              size='s'
            >
              <p className='flex items-center'>
                {capitalize(position.status)}

              </p>
            </Button>
          </Conditional>
          <Conditional if={position.status === 'liquidated' || position.status === 'sold' || position.status === 'repaid'}>
            <span className='my-auto text-center'>{timeSince((position.closeDate?.getTime() ?? 0) / 1000)}</span>
          </Conditional>
          <Conditional if={position.status === 'active' && !IS_TAKE_PROFIT_ENABLED}>
            <span className='my-auto text-center'>{timeSince(position.openDate.getTime() / 1000)}</span>
          </Conditional>
          <Conditional if={position.status === 'recalled'}>
            <div
              className='tooltip tooltip-primary my-auto text-left'
              data-tip='Lender has recalled this position. Close within 48 hours to avoid liquidation.'
            >
              <IconAltHint width={16}/>
            </div>
          </Conditional>
        </div>
        <Conditional if={position.status === 'active' && !IS_TAKE_PROFIT_ENABLED}>
          <div className='flex'>
            <IconSolscan className='my-auto mr-[10px] cursor-pointer' onClick={() => window.open(`https://solscan.io/account/${position?.publicKey}`, '_blank', 'noopener,noreferrer')}/>
            <Conditional if={!isMobile}>
              <IconShare className='my-auto mr-[10px] cursor-pointer' onClick={() => onShare?.(position)}/>
            </Conditional>
            <IconBirdeye className='my-auto mr-[6px] cursor-pointer' onClick={() => window.open(`https://birdeye.so/token/${position?.pool.baseCurrency?.address}?chain=solana`, '_blank', 'noopener,noreferrer')}/>
          </div>
        </Conditional>
        <Conditional if={position.status === 'liquidated' || position.status === 'sold' || position.status === 'repaid'}>
          <div className='flex'>
            <IconSolscan className='my-auto mr-[10px] cursor-pointer' onClick={() => window.open(`https://solscan.io/account/${position?.publicKey}`, '_blank', 'noopener,noreferrer')}/>
            <Conditional if={!isMobile}>
              <IconShare className='my-auto mr-[10px] cursor-pointer' onClick={() => onShare?.(position)}/>
            </Conditional>
            <IconBirdeye className='my-auto mr-[6px] cursor-pointer' onClick={() => window.open(`https://birdeye.so/token/${position?.pool.baseCurrency?.address}?chain=solana`, '_blank', 'noopener,noreferrer')}/>
          </div>
        </Conditional>
        <Conditional if={position.status === 'active' && IS_TAKE_PROFIT_ENABLED}>
          <div className='flex'>
            <Button className='mr-[10px]' color='main' size='s' onClick={() => onTakeProfit?.(position)}>
              <div className='flex flex-row items-center justify-center px-2'>
                <span>TP</span>
                {/* {takeProfitPrice > currentPrice && <IconGreenCheck className='ml-[5px]'/>} */}
              </div>
            </Button>
          </div>
        </Conditional>
        <Conditional if={position.status === 'active'}>
          <div className='flex'>
            <Button color='main' size='s' onClick={() => onClose?.(position)}>Close</Button>
          </div>
        </Conditional>
      </div>
      <div className='bg-main/5 flex flex-col gap-[10px] px-[10px] py-[15px]'>
        <div className='flex h-[30px] justify-between'>
          <div className='flex items-center gap-2 text-base'>
            <TokenImage altName={baseCurrency?.name ?? ''} logoURI={baseCurrency?.logoURI ?? null}/>
            <LabelledValue
              size='lg'
              value={`${formatSol(position.collateralSize.toNumber(), CRYPTO_DECIMAL_POINTS)} ${baseSymbol}`}
            />
          </div>
          <LabelledValue
            isLoading={position.status === 'active' ? (!quotePriceInUSD || !currentPrice) : false}
            size='lg'
            subValue={position.status === 'active' ? formatPercent(priceChangePercentage.toNumber(), 1) : formatPercent(closingChangePercentage.toNumber(), 1)}
            subValueClassName={getTextColorByString(formatPercent(position.status === 'active' ? priceChangePercentage.toNumber() : closingChangePercentage.toNumber(), 1))}
            value={`${formatSol(displayedPositionSize)} ${quoteSymbol}`}
          />
        </div>
        <div className='flex h-[17px] items-center justify-between gap-[10px]'>
          <LabelledValue
            className='w-[150px]'
            isLoading={position.status === 'active' ? !collateralPrice : false}
            label='LTV'
            labelHint='Current vs. liquidation LTV. Risk of liquidation arises when current LTV ≥ liquidation LTV.'
            labelHintClassName='tooltip-right'
            value={`${dispLTV}%/${LIQUIDATION_LTV}%`}
            valueClassName={position.status === 'active' ? getColorOfLTV(currentLTV.toNumber())
              : getColorOfLTV(position.borrowedAmount.div(position.positionSize).multipliedBy(100).toNumber())}
          />
          <LabelledValue
            className='flex-1'
            isLoading={position.status === 'active' && (!quotePriceInUSD || !collateralPrice)}
            label='PnL'
            subValue={positionRoi === undefined ? '--' : `${formatPercent(positionRoi, 1)}`}
            subValueClassName={getTextColorByString(formatSol(positionRoi ?? NaN))}
            value={positionPnl === undefined ? '--' : `${formatSol(positionPnl)} ${quoteSymbol}`}
            valueClassName={getTextColorByString(formatSol(positionPnl ?? NaN))}
          />
        </div>
      </div>
      <div className='flex flex-col justify-between'>
        <button className={clsxm('bg-main desk:hover:bg-opacity-20 flex h-[36px] items-center justify-center bg-opacity-10 transition')} onClick={onToggleDetails}>
          <span>{isExpanded ? 'Hide Details' : 'Show Details'}</span>
          <div className='my-auto ml-[5px]'>{isExpanded ? <IconUp height={20}/> : <IconDown height={20}/>}</div>
        </button>
        <Conditional if={isExpanded}>
          <div className='bg-main/10 p-[10px]'>
            <Conditional if={IS_TAKE_PROFIT_ENABLED && position.status === 'active'}>
              <LabelledValue label='Open Date' subValue={timeSince(position.openDate.getTime() / 1000)} value={startDate}/>
            </Conditional>
            <Conditional if={!IS_TAKE_PROFIT_ENABLED || position.status !== 'active'}>
              <LabelledValue label='Open Date' value={startDate}/>
            </Conditional>
            <LabelledValue label='Close Date' value={position.status === 'active' ? '--' : closeDate}/>
            <LabelledValue label='Margin' value={`${formatSol(position.initialMargin.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue label='Position Size' subValue={`${position.initialLeverage.toNumber().toFixed(1)}x`} value={`${formatSol(position.initialPositionSize.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue label='Entry Price' value={`${formatSol(position.entryPrice.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue isLoading={position.status === 'active' && !quotePriceInUSD} label='Current Price' labelHint='The most recent reference price of the collateral. For closed positions, this reflects the reference price at the time of closure.' value={`${position.status === 'active' ? formatSol(currentPrice) : formatSol(position.price.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue label='Liquidation Price' labelHint='If asset price ≤ this amount, your position risks liquidation.' value={`${formatSol(liquidationPrice)} ${quoteSymbol}`}/>
            <Conditional if={IS_TAKE_PROFIT_ENABLED && position.status === 'active'}>
              <LabelledValue label='Take Profit Price' value='--'/>
            </Conditional>
            <LabelledValue label='Daily Interest' value={`${formatSol(averageDailyInterest.toNumber())}%`}/>
            <Conditional if={position.state === 'open'}>
              <LabelledValue
                label='Interest Accrued'
                labelHint='Interest accrued daily at 00:00 UTC, auto-collected every 7 days if it exceeds 0.1 SOL or equivalent.'
                subValue={`${position.daysSinceLastInterest === 0 ? '0' : -position.daysSinceLastInterest}d`}
                subValueClassName={clsxm({
                  'text-main': position.daysSinceLastInterest === 0,
                  'text-yellow': position.daysSinceLastInterest >= 5 && position.daysSinceLastInterest < 7,
                  'text-purple': position.daysSinceLastInterest >= 7,
                })}
                value={`${formatSol(position.interestAccrued.toNumber(), CRYPTO_DECIMAL_POINTS)} ${quoteSymbol}`}
              />
            </Conditional>
            <Conditional if={position.state === 'closed'}>
              <LabelledValue label='Interest Collected' labelHint='Interest collected so far (auto-collected every 7 days if it exceeds 0.1 SOL or equivalent).' value={`${formatSol(-position.interestCollected.toNumber(), CRYPTO_DECIMAL_POINTS)} SOL`}/>
            </Conditional>
          </div>
        </Conditional>
      </div>
    </div>
  )
}
