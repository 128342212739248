import { stringToNumber } from '@/utils'
import { type ActionStakeButtonMode } from '../components/ActionStakeButton'

type GetActionStakeButtonModeProps = {
  isOnline: boolean
  mode: 'stake' | 'unstake'
  hasAccess: boolean
  connected: boolean
  confirming: boolean
  amount: string
  available: number
  isStakingPoolOverfilled: boolean
}

export const getActionStakeButtonMode = ({
  isOnline,
  mode,
  connected,
  hasAccess,
  confirming,
  amount,
  available,
  isStakingPoolOverfilled,
}: GetActionStakeButtonModeProps): ActionStakeButtonMode => {
  const numberAmount = stringToNumber(amount)
  switch (true) {
    case isStakingPoolOverfilled && mode !== 'unstake':
      return 'discord'
    case !connected:
      return 'connect-wallet'
    case !isOnline:
      return 'no-connection'
    case !hasAccess:
      return 'restricted'
    case confirming:
      return 'confirming'
    case amount === '' || amount === '0' || numberAmount === 0:
      return 'enter-amount'
    case numberAmount > available:
      return 'insufficient-funds'
    case numberAmount !== 0 && !confirming && mode === 'stake':
      return 'stake'
    case numberAmount !== 0 && !confirming && mode === 'unstake':
      return 'unstake'
    default:
      return 'restricted'
  }
}
