import { useLavarage } from '@/app/providers/LavarageProvider'
import { COLLECTION_KEY } from '@/config'
import { fetchAllDigitalAssetByOwner, mplTokenMetadata } from '@metaplex-foundation/mpl-token-metadata'
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'

import { type PublicKey } from '@metaplex-foundation/umi'
import { createContext, type PropsWithChildren, useEffect, useState } from 'react'
import { type LavarageService } from '../../services/LavarageService.js'

type Props = PropsWithChildren<{
  userAddress: string | undefined
}>

type ContextValue = {
  hasLavaRockNFT: boolean
  isCheckingNFT: boolean
}
interface Collection {
  value?: {
    key: string
  }
}

async function checkNftAccess(lavarage: LavarageService, userAddress?: string): Promise<ContextValue> {
  const umi = createUmi(lavarage.program.provider.connection).use(mplTokenMetadata())
  if (!userAddress || !umi || !lavarage) return { hasLavaRockNFT: false, isCheckingNFT: false }

  try {
    const assets = await fetchAllDigitalAssetByOwner(umi, lavarage.program.provider.publicKey as PublicKey)
    for (const asset of assets) {
      const currentCollection = asset.metadata.collection as Collection
      if (currentCollection?.value?.key === COLLECTION_KEY) {
        return { hasLavaRockNFT: true, isCheckingNFT: false }
      }
    }
    return { hasLavaRockNFT: false, isCheckingNFT: false }
  }
  catch (error) {
    console.error('Error checking for Lava Rock NFT:', error)
    return { hasLavaRockNFT: false, isCheckingNFT: false }
  }
}

export const WalletInfoContext = createContext<ContextValue>({
  hasLavaRockNFT: false,
  isCheckingNFT: false,
})

export function WalletInfoProvider({ children, userAddress }: Props) {
  const [contextValue, setContextValue] = useState<ContextValue>({ hasLavaRockNFT: false, isCheckingNFT: true })
  const lavarages = useLavarage()
  const lavarage = lavarages[0]
  useEffect(() => {
    async function fetchData() {
      if (!userAddress) {
        setContextValue({ hasLavaRockNFT: false, isCheckingNFT: false })
        return
      }
      const result = await checkNftAccess(lavarage, userAddress)
      setContextValue(result)
    }
    fetchData()
  }, [userAddress])

  return (
    <WalletInfoContext.Provider
      value={contextValue}
    >
      {children}
    </WalletInfoContext.Provider>
  )
}
