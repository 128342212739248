/** Core service with all the formulas and calculations of the leverage protocol */

import { getRelatedPool } from '@/utils'
import { type Pool, type Token } from '@lavarage/entities'
import BigNumber from 'bignumber.js'
import { type TokenInfo } from './types'

export class CoreService {
  /**
   * Calculates leverage option based on maximum leverage and factor.
   * @param {Object} params - Parameters.
   * @param {number} params.maxLeverage - Maximum leverage allowed.
   * @param {number} params.factor - Leverage factor.
   * @returns {number} Calculated leverage option.
   */
  calculateLeverageOption({ maxLeverage, factor }: { maxLeverage: number; factor: number }): number {
    const maxLeverageBN = BigNumber(maxLeverage)
    const factorBN = BigNumber(factor)
    if (maxLeverageBN.isNaN() || factorBN.isNaN()) {
      throw new TypeError('Invalid input types. Both maxLeverage and factor must be numbers.')
    }
    return maxLeverageBN.minus(1).multipliedBy(factorBN).plus(1).decimalPlaces(1, BigNumber.ROUND_DOWN).toNumber()
  }

  /**
   * Calculates borrowed amount.
   * @param {Object} params - Parameters.
   * @param {number} params.initialMargin - Initial margin.
   * @param {number} params.leverage - Leverage.
   * @returns {number} Borrowed amount.
   */
  borrowedAmount({ initialMargin, leverage }: { initialMargin: number; leverage: number }): number {
    const initialMarginBN = BigNumber(initialMargin)
    const leverageBN = BigNumber(leverage)
    if (initialMarginBN.isNaN() || leverageBN.isNaN()) {
      throw new TypeError('Invalid input types for borrowedAmount. Both initialMargin and leverage must be numbers.')
    }
    return initialMarginBN.multipliedBy(leverageBN).minus(initialMarginBN).toNumber()
  }

  /**
   * Calculates position size.
   * @param {Object} params - Parameters.
   * @param {number} params.baseCurrency - Base currency.
   * @param {number} params.basePriceUSD - Base price in USD.
   * @param {number} params.quotePriceUSD - Quote price in USD.
   * @returns {number} Position size.
   */
  positionSize({ baseCurrency, basePriceUSD, quotePriceUSD }: { baseCurrency: number; basePriceUSD: number; quotePriceUSD: number }): number {
    const baseCurrencyBN = BigNumber(baseCurrency)
    const basePriceUSDBN = BigNumber(basePriceUSD)
    const quotePriceUSDBN = BigNumber(quotePriceUSD)
    if (baseCurrencyBN.isNaN() || basePriceUSDBN.isNaN() || quotePriceUSDBN.isNaN()) {
      throw new TypeError('Invalid input types for positionSize. All inputs must be numbers.')
    }
    return baseCurrencyBN.multipliedBy(basePriceUSDBN).dividedBy(quotePriceUSDBN).toNumber()
  }

  /**
   * Calculates collateral value.
   * @param {Object} params - Parameters.
   * @param {number} params.initialMargin - Initial margin.
   * @param {number} params.leverage - Leverage.
   * @param {number} params.quoteToBase - Quote to base ratio.
   * @returns {number} Collateral value.
   */
  collateralValue({ initialMargin, leverage, quoteToBase }: { initialMargin: number; leverage: number; quoteToBase: number }): number {
    const initialMarginBN = BigNumber(initialMargin)
    const leverageBN = BigNumber(leverage)
    const quoteToBaseBN = BigNumber(quoteToBase)
    if (initialMarginBN.isNaN() || leverageBN.isNaN() || quoteToBaseBN.isNaN()) {
      throw new TypeError('Invalid input types for collateralValue. All inputs must be numbers.')
    }
    return initialMarginBN.multipliedBy(leverageBN).multipliedBy(quoteToBaseBN).toNumber()
  }

  /**
   * Calculates open fee.
   * @param {Object} params - Parameters.
   * @param {number} params.baseCurrency - Base currency.
   * @param {number} params.leverage - Leverage.
   * @param {number} params.fee - Fee percentage.
   * @returns {number} Open fee.
   */
  openFee({ baseCurrency, leverage, fee }: { baseCurrency: number; leverage: number; fee: number }): number {
    const baseCurrencyBN = BigNumber(baseCurrency)
    const leverageBN = BigNumber(leverage)
    const feeBN = BigNumber(fee)
    if (baseCurrencyBN.isNaN() || leverageBN.isNaN() || feeBN.isNaN()) {
      console.error(`Invalid input types for openFee. All inputs must be numbers. baseCurrency: ${baseCurrency}, leverage: ${leverage}, fee: ${fee}`)
      // throw new TypeError('Invalid input types for openFee. All inputs must be numbers.')
    }
    if (leverageBN.isEqualTo(1)) return 0

    return this.borrowedAmount({ initialMargin: baseCurrency, leverage: leverageBN.toNumber() }) * feeBN.dividedBy(100).toNumber()
  }

  /**
   * Calculates interest.
   * @param {Object} params - Parameters.
   * @param {number} params.initialMargin - Initial margin.
   * @param {number} params.leverage - Leverage.
   * @param {number} params.interestRate - Interest rate.
   * @param {number} params.durationInDays - Duration in days.
   * @returns {number} Interest.
   */
  interest({
    initialMargin,
    leverage,
    interestRate,
    durationInDays,
  }: {
    initialMargin: number
    leverage: number
    interestRate: number
    durationInDays: number
  }): number {
    const initialMarginBN = BigNumber(initialMargin)
    const leverageBN = BigNumber(leverage)
    const interestRateBN = BigNumber(interestRate)
    const durationInDaysBN = BigNumber(durationInDays)
    if (initialMarginBN.isNaN() || leverageBN.isNaN() || interestRateBN.isNaN() || durationInDaysBN.isNaN()) {
      throw new TypeError('Invalid input types for interest. All inputs must be numbers.')
    }

    return (
      this.borrowedAmount({ initialMargin: initialMarginBN.toNumber(), leverage: leverageBN.toNumber() }) *
      interestRateBN.dividedBy(100).dividedBy(365).multipliedBy(durationInDaysBN).toNumber()
    )
  }

  /**
   * Calculates entry price.
   * @param {Object} params - Parameters.
   * @param {number} params.quotePriceUSD - Quote price in USD.
   * @param {number} params.basePriceUSD - Base price in USD.
   * @returns {number} - Entry price.
   */
  entryPrice({ quotePriceUSD, basePriceUSD }: { quotePriceUSD: number; basePriceUSD: number }): number {
    const quotePriceUSDBN = BigNumber(quotePriceUSD)
    const basePriceUSDBN = BigNumber(basePriceUSD)
    if (quotePriceUSDBN.isNaN() || basePriceUSDBN.isNaN()) {
      throw new TypeError('Invalid input types for entryPrice. Both inputs must be numbers.')
    }

    return basePriceUSDBN.dividedBy(quotePriceUSDBN).toNumber()
  }

  /**
   * Calculates liquidation price.
   * @param {Object} params - Parameters.
   * @param {number} params.borrowedAmount - Borrowed amount.
   * @param {number} params.interestAccrued - Accrued interest.
   * @param {number} params.collateralValue - Collateral value.
   * @param {number} params.liquidationLTV - Liquidation loan-to-value ratio.
   * @returns {number} Liquidation price.
   */
  liquidationPrice({
    borrowedAmount,
    interestAccrued,
    collateralValue,
    liquidationLTV,
  }: {
    borrowedAmount: BigNumber
    interestAccrued: number
    collateralValue: number
    liquidationLTV: number
  }): number {
    const interestAccruedBN = BigNumber(interestAccrued)
    const collateralValueBN = BigNumber(collateralValue)

    return borrowedAmount
      .plus(interestAccruedBN)
      .dividedBy(collateralValueBN.multipliedBy(liquidationLTV / 100))
      .toNumber()
  }

  /**
   * Calculates current loan-to-value ratio.
   * @param {Object} params - Parameters.
   * @param {number} params.loanAmount - Loan amount.
   * @param {number} params.borrowedAmount - Borrowed amount.
   * @param {number} params.baseMarketPriceUSD - Base market price in USD.
   * @returns {number} Current loan-to-value ratio.
   */
  currentLTV({ loanAmount, borrowedAmount, baseMarketPriceUSD }: { loanAmount: number; borrowedAmount: number; baseMarketPriceUSD: number }): number {
    const loanAmountBN = BigNumber(loanAmount)
    const borrowedAmountBN = BigNumber(borrowedAmount)
    const baseMarketPriceUSDBN = BigNumber(baseMarketPriceUSD)
    if (loanAmountBN.isNaN() || borrowedAmountBN.isNaN() || baseMarketPriceUSDBN.isNaN()) {
      throw new TypeError('Invalid input types for currentLTV. All inputs must be numbers.')
    }

    return loanAmountBN.dividedBy(baseMarketPriceUSDBN.multipliedBy(borrowedAmountBN)).toNumber()
  }

  /**
   * Calculates maximum leverage.
   * @param {Object} params - Parameters.
   * @param {Pool[]} params.pools - Pools.
   * @param {{ address: string; whitelisted: boolean }} params.baseToken - Base token.
   * @param {number} params.solPrice - Price of token in SOL.
   * @returns {number} Maximum leverage.
   */
  maxLeverage({ pools, baseToken, quoteToken, solPrice }: { pools: Pool[]; baseToken: Token; quoteToken: Token; solPrice: number }): number | null {
    const solPriceBN = BigNumber(solPrice)
    const relatedPool = getRelatedPool({ address: baseToken?.address } as TokenInfo, pools, quoteToken?.address)

    if (!baseToken?.whitelistedForAddress?.includes(quoteToken.address) || !relatedPool) return null

    const marketPriceOfBasedTokenInQuoteCurrency = BigNumber(1).dividedBy(solPriceBN)
    const maxBorrowLTV = BigNumber.min(relatedPool.maxBorrow.dividedBy(marketPriceOfBasedTokenInQuoteCurrency), BigNumber(0.875))
    // without -1 transaction fails to complete
    const maxLeverageValue = BigNumber(1).dividedBy(BigNumber(1).minus(maxBorrowLTV)).multipliedBy(10).minus(1).dividedBy(10)

    return maxLeverageValue.isGreaterThan(1) ? maxLeverageValue.decimalPlaces(1).toNumber() : null
  }
}

export const coreService = new CoreService()
