import { usePreferencesStore } from '@/app/stores'
import { type TokenInfo } from '@/services'
import { nodeWalletService } from '@/services/NodeWalletService'
import { getRelatedPool } from '@/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { useCallback } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { usePoolsQuery } from './usePoolsQuery'

export const useTokenLiquidity = () => {
  const [baseTokenLong, setBaseTokenLong] = usePreferencesStore(useShallow(s => [s.baseTokenLong, s.setBaseTokenLong]))
  const [quoteTokenLong] = usePreferencesStore(useShallow(s => [s.quoteTokenLong]))
  const { pools } = usePoolsQuery()
  const queryClient = useQueryClient()
  // console.log('useTokenLiquidity -> pools', pools)

  const updateTokenLiquidity = useCallback(
    async (token: TokenInfo) => {
      // TODO: get the token liquidity for the selected token. the available liquidity should be paired with the quote token and the base token
      const nodeWallets = await nodeWalletService.getNodeWallets()
      const relatedPool = getRelatedPool(token, pools, quoteTokenLong?.address)
      // console.log('useTokenLiquidity -> relatedPool', relatedPool)
      const relatedNodeWallet = nodeWallets.find(nodeWallet => nodeWallet.publicKey === relatedPool?.nodeWallet.publicKey)
      const updatedToken = {
        ...token,
        availableLiquidity:
        BigNumber.minimum(
          relatedNodeWallet?.totalFunds || 0,
          relatedPool.maxExposure.eq(0) ? relatedNodeWallet?.totalFunds || 0 : relatedPool.maxExposure.minus(relatedPool.currentExposure),
        ).toNumber() || 0,
        dailyInterest: relatedPool?.apr.div(365).multipliedBy(100).toNumber() || 0,
      }

      setBaseTokenLong(updatedToken)

      return updatedToken
    },
    [baseTokenLong.address, pools, quoteTokenLong?.address],
  )

  const query = useQuery({
    queryKey: ['tokenLiquidity', baseTokenLong?.address, pools.length, `${pools[0]?.publicKey} ${pools[pools.length - 1]?.publicKey}`],
    queryFn: () => updateTokenLiquidity(baseTokenLong),
    enabled: !!baseTokenLong && !!pools,
    refetchInterval: 30000,
  })

  const refetchWithToken = (token: TokenInfo) => {
    return queryClient.fetchQuery({ queryKey: ['tokenLiquidity', token, pools], queryFn: () => updateTokenLiquidity(token) })
  }

  return { ...query, refetchWithToken }
}
