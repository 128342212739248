import { trackDemoModeDiscordBannerGA } from '@/app/analytics'
import { clsxm } from '@/app/helpers/clsxm'
import { IconX } from '@/assets'
import { NotificationService } from '@/services/NotificationService'
import React from 'react'
// import { useLocation, useNavigate } from 'react-router-dom'
import { FLASH_NOTIFICATION_LINK, FLASH_NOTIFICATION_MESSAGE } from '../app.config.js'
// import { useTokensQuery } from '../hooks/queries'
// import { usePreferencesStore } from '../stores'

type FlashNotificationProps = {
  setVisibility: (visible: boolean) => void
  dismissible: boolean
  message: string
  className?: string
}

export const FlashNotification: React.FC<FlashNotificationProps> = ({
  message,
  setVisibility,
  dismissible,
  className,
}: FlashNotificationProps) => {
  // const setBaseToken = usePreferencesStore(state => state.setBaseToken)
  // const tokens = useTokensQuery()
  // const navigate = useNavigate()
  // const location = useLocation()
  const link = FLASH_NOTIFICATION_LINK
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // if (e.target instanceof HTMLElement && e.target.matches('[data-address]')) {
    //   const address = e.target.getAttribute('data-address')
    //   if (address && setBaseToken) {
    //     const targetToken = tokens.tokens?.find(token => token.address === address)
    //     if (targetToken) {
    //       setBaseToken(targetToken)
    //       if (location.pathname !== '/') navigate('/')
    //     }
    //   }
    // }
    if (link) {
      trackDemoModeDiscordBannerGA()
      window.open(link, '_blank')
    }
  }

  const onClose = () => {
    localStorage.setItem('flash-notification', NotificationService.hashMessage(FLASH_NOTIFICATION_MESSAGE).toString())
    setVisibility(false)
  }

  return (
    <>
      <div
        className={clsxm(
          'bg-purple relative flex w-full items-center justify-center px-5 py-[10px] text-center transition',
          {
            'cursor-default': !link,
            'cursor-pointer': link,
            'desk:hover:bg-opacity-70': link,
          },
          className,
        )}
        onClick={handleClick}
      >
        <span className='mr-[-20px] flex-1 text-balance' dangerouslySetInnerHTML={{ __html: message }}/>
        {dismissible && (
          <IconX
            className='ml-2 size-[20px] cursor-pointer'
            onClick={onClose}
          />
        )}
      </div>
    </>
  )
}
