import { clsxm } from '@/app/helpers/clsxm'

interface TradeTabItemProps {
  isActive: boolean
  handleClick: () => void
  label: string
}
function getColorByLabel(label: string) {
  switch (label) {
    case 'Long':
      return 'green'
    case 'Swap':
      return 'main'
    case 'Short':
      return 'alt'
    case 'Def':
      return 'main'
    default:
      return 'main'
  }
}
export const TradeTabItem = ({ isActive, handleClick, label }: TradeTabItemProps) => {
  return (
    <button
      className={clsxm(`text-${isActive ? getColorByLabel(label) : 'main'} flex h-full w-[80px] items-center justify-center rounded-full transition`, {
        'bg-alt bg-opacity-10': isActive,

        'desk:hover:opacity-70': !isActive,
        'pointer-events-none': isActive,
      })}
      role='tab'
      onClick={handleClick}
    >
      <div>{label}</div>
    </button>
  )
}
