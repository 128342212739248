import { Button } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { TakeProfitModalButton } from '@/app/pages/positions/components'
import { calculatePositionMetrics, getTakeProfitModalButtonMode } from '@/app/pages/positions/utils'
import { useAlertsStore } from '@/app/stores'
import { formatSol } from '@/utils/formatters'
import { type Position } from '@lavarage/entities'
import { forwardRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { IconHintRed } from '../../../../assets/index.js'
import { MIN_TAKE_PROFIT_PERCENT, SLIPPAGE_PERCENT } from '../../../app.config.js'
import { LabelledValue } from '../../../components/LabelledValue.js'

type Props = {
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  onExit?: () => void
}

export const TakeProfitModal = forwardRef<HTMLDivElement, Props>(({ position, prices, timezone, onExit }, ref) => {
  const [addAlert, removeAlert, loading, setLoading, setConfirming, confirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.loading, state.setLoading, state.setConfirming, state.confirming]))
  const isOnline = useNetworkStatus()
  const [takeProfitPercent, setTakeProfitPercent] = useState<number | null>(MIN_TAKE_PROFIT_PERCENT)
  const [isTakeProfitSet, setIsTakeProfitSet] = useState<boolean>(false)
  const { pool: { baseCurrency, quoteCurrency } } = position
  if (!baseCurrency || !quoteCurrency) return null
  const quoteSymbol = position.pool.quoteCurrency?.symbol as string
  const collateralPrice = prices?.[baseCurrency.address]?.price
  const quotePriceInUSD = prices?.[quoteCurrency?.address]?.price
  const {
    liquidationPrice,
  } = calculatePositionMetrics(position, quotePriceInUSD, collateralPrice, timezone)
  const takeProfitPrice = position.entryPrice.toNumber() * (1 + ((takeProfitPercent ?? 0) / 100))
  const estimatedPositionSize = (position.initialPositionSize).times(1 + ((takeProfitPercent ?? 0) / 100))
  const estimatedProfit = estimatedPositionSize.minus(position.initialPositionSize)
  const minReceived = (estimatedPositionSize).times(1 - (SLIPPAGE_PERCENT / 100))
  const takeProfitModalButtonMode = getTakeProfitModalButtonMode({
    isOnline,
    confirming,
    isTakeProfitSet,
    takeProfitPercent,
    isRestricted: false,
  })
  const isButtonDisabled = loading || confirming
  const isInvalidTakeProfit: boolean = takeProfitPercent === null || takeProfitPercent < MIN_TAKE_PROFIT_PERCENT

  const handleSetTp = async () => {
    setTakeProfitPercent(takeProfitPercent)
    setIsTakeProfitSet(true)
  }

  const handleRemoveTp = async () => {
    setTakeProfitPercent(null)
    setIsTakeProfitSet(false)
  }

  return (
    <Modal className='z-40'>
      <div ref={ref} className='bg-vibrant modal-box max-w-[380px] p-[10px] pb-5 text-sm'>
        <div className='mb-[10px] flex grow items-center justify-center gap-[5px] py-[8.5px] text-base'>
          <div className='w-[44px]'/>
          <span>Take Profit</span>
          <span className='bg-alt rounded-full px-[6px] py-0.5 text-[10px] font-normal leading-3'>BETA</span>
        </div>
        <div className='flex flex-col gap-5'>
          <section className='border-main/10 flex flex-col gap-[5px] rounded-lg border p-[10px]'>
            <LabelledValue label='Position Size' value={`${formatSol(position.initialPositionSize.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue label='Liquidation Price' value={`${formatSol(liquidationPrice)} ${quoteSymbol}`}/>
            <LabelledValue label='Entry Price' labelClassName='font-bold' value={`${formatSol(position.entryPrice.toNumber())} ${quoteSymbol}`} valueClassName='font-bold'/>
          </section>

          <section className='bg-alt/10 flex flex-col gap-[5px] rounded-lg p-[10px] text-sm '>
            <section className='border-main/10 flex flex-col gap-[5px] rounded-lg border px-4 py-[10px]'>
              <div className='flex flex-col gap-[5px] px-6'>
                <div className='flex justify-center text-xs '>
                  <LabelledValue hintPosition='left' label='TAKE PROFIT PERCENTAGE' labelClassName='opacity-40 text-xs' labelHint={`The minimum take profit percentage is ${MIN_TAKE_PROFIT_PERCENT}%`}/>
                </div>
                <div className='flex flex-row items-center justify-center'>
                  <input className='text-background mr-[5px] w-full rounded-md p-1 text-right text-base outline-none' id='take-profit' name='take-profit' type='number' value={takeProfitPercent ?? ''} onChange={e => setTakeProfitPercent(e.target.value ? Number(e.target.value) : null)} onFocus={() => setTakeProfitPercent(null)}/>
                  <span className='opacity-70'>%</span>
                </div>
                <p className='text-gray flex justify-start text-xs'>{`Take profit price ${takeProfitPercent === null ? '--' : formatSol(takeProfitPrice)} ${quoteSymbol}`}</p>
              </div>
              {(isInvalidTakeProfit) && (
                <div className='bg-main/5 text-alt flex items-center justify-center rounded-md p-2 text-xs'>
                  <IconHintRed className='text-alt'/>
                  <span className='ml-[5px]'>
                    Take profit percentage should be at least
                    {' '}
                    <span className='cursor-pointer underline decoration-dotted' onClick={() => setTakeProfitPercent(MIN_TAKE_PROFIT_PERCENT)}>
                      {MIN_TAKE_PROFIT_PERCENT}
                      %
                    </span>
                  </span>
                </div>
              )}

            </section>
            <section>
              <LabelledValue label='Estimated Position Size' value={`${isInvalidTakeProfit ? '--' : formatSol(estimatedPositionSize.toNumber())} ${quoteSymbol}`}/>
              <LabelledValue label='Estimated Profit' value={`${isInvalidTakeProfit ? '--' : formatSol(estimatedProfit.toNumber())} ${quoteSymbol}`} valueClassName='text-green font-bold'/>
              <LabelledValue
                hintInternalComponent={
                  <div className='text-left'>
                    <span>Limit order function is on a best-effort basis. The order will only be filled if:</span>
                    <br/>
                    <span>1) Trigger Price is reached and market price not more than 5% below Trigger Price</span>
                    <br/>
                    <span>{`2) Maximum slippage < ${SLIPPAGE_PERCENT}%`}</span>
                  </div>
                }
                label='Minimum Received'
                labelClassName='text-gray'
                labelHintClassName='text-gray'
                value={`${isInvalidTakeProfit ? '--' : formatSol(minReceived.toNumber())} ${quoteSymbol}`}
                valueClassName='text-gray'
              />
            </section>
            <section className='text-gray flex justify-center px-10 py-2 text-center'>
              Take Profit orders stay active despite changes to collateral or position size.
            </section>
            <section>
              <div className='flex flex-row justify-center gap-[6px]'>
                <Button color='main' disabled={isButtonDisabled} size='s' onClick={onExit}>Cancel</Button>
                <TakeProfitModalButton createTakeProfit={handleSetTp} removeTakeProfit={handleRemoveTp} takeProfitModalButtonMode={takeProfitModalButtonMode}/>
              </div>
            </section>
            <section/>
          </section>
        </div>
      </div>

    </Modal>
  )
})
