import { Button, Conditional } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { type AlertType, type IAlert, useAlertsStore } from '@/app/stores/useAlertsStore'
import { type HTMLAttributes, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { IconNotification } from '../../../assets/index.js'
import { usePreferencesStore } from '../../stores/usePreferencesStore.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  alert: IAlert
  index: number
}

export function Alert({ alert, index }: Props) {
  const { reasonMessage, type, tx } = alert
  const [visible, setVisible] = useState(false)
  const removeAlert = useAlertsStore(state => state.removeAlert)
  const hideAlert = useAlertsStore(state => state.hideAlert)
  const [setIsNotifiMenuVisible] = usePreferencesStore(useShallow(s => [s.setIsNotifiMenuVisible])) as [React.Dispatch<React.SetStateAction<boolean>>]
  const dismissAlert = () => {
    hideAlert(index)
    setVisible(false)
    removeAlert(index)
  }
  const handleClickBell = () => {
    dismissAlert()
    setIsNotifiMenuVisible(true)
  }
  useEffect(() => {
    if (alert.visible === true) {
      setVisible(true)
    }
  }, [alert.visible])
  const alertMessageByType = (t: AlertType) => {
    switch (t) {
      case 'inProgress':
        return 'Please wait while transaction is in progress...'
      case 'transactionFails':
        return `An error has occurred while completing the transaction: ${reasonMessage}`
      case 'transactionWaiting':
        return 'Transaction is taking longer than usual and may have expired due to network congestion.'
      case 'error':
        return `An error has occurred: ${reasonMessage}`
      default:
        break
    }
  }
  const testColorByType = (t: AlertType) => {
    switch (t) {
      case 'inProgress':
        return 'text-main'
      case 'success':
        return 'text-main'
      case 'transactionFails':
      case 'transactionWaiting':
        return 'text-alt'
      case 'error':
        return 'text-alt'
      default:
        break
    }
  }
  return (
    <>
      {visible && <div
        className='bg-translucent bg-opacity/60 fixed inset-0 z-40'
      />}
      <div
        className={clsxm(
          'bg-translucent fixed left-1/2 top-0 z-50 w-[300px] -translate-x-1/2 -translate-y-20 transform rounded-lg px-[10px] py-5 opacity-0 transition-all duration-500',
          {
            'translate-y-20 opacity-100': visible,
            '-translate-y-20 opacity-0': !visible,
          },
        )}
      >
        <div className='text-center'>
          <Conditional if={type !== 'success'}>
            <p className={`${testColorByType(type)} mb-[10px] text-sm`}>{alertMessageByType(type)}</p>
          </Conditional>
          <Conditional if={type === 'success'}>
            <div className={`${testColorByType(type)} mb-[10px] flex flex-col gap-[10px] text-sm`}>
              <p>
                Transaction confirmed.
              </p>
              <p>
                Finalisation and position updates may take a few minutes.
              </p>
              <p>
                If you haven’t yet, sign up for new loan offers and updates on your positions by selecting the bell icon!
              </p>
            </div>
          </Conditional>
          <div className='flex items-center justify-center gap-[10px]'>
            {type !== 'error' && type !== 'transactionWaiting' && (
              <a href={`https://solscan.io/tx/${tx}`} rel='noreferrer' target='_blank'>
                <Button color={type === 'inProgress' ? 'solid' : 'main'} size='s'>
                  View on Solscan
                </Button>
              </a>
            )}
            {type === 'transactionWaiting' && (
              <div className='flex justify-center gap-[10px]'>
                <a href={`https://solscan.io/tx/${tx}`} rel='noreferrer' target='_blank'>
                  <Button color='main' size='s'>
                    View on Solscan
                  </Button>
                </a>
                <Button color='alt' size='s' onClick={dismissAlert}>
                  Stop Waiting
                </Button>
              </div>
            )}

            <Conditional if={type === 'success'}>
              <div className='mx-[15px] cursor-pointer' onClick={handleClickBell}>
                <IconNotification/>
              </div>
            </Conditional>
            <Conditional if={type === 'success' || type === 'transactionFails' || type === 'error'}>
              <Button color={type === 'success' ? 'solid' : 'alt'} size='s' onClick={dismissAlert}>
                OK
              </Button>
            </Conditional>

          </div>
          {type === 'transactionFails' && alert.sentryId && (
            <span className='mt-[10px] text-[10px] opacity-60'>
              ID:
              {alert.sentryId}
            </span>
          )}
        </div>
      </div>
    </>
  )
}
