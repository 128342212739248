import { trackWalletConnectGA } from '@/app/analytics'
import { Button } from '@/app/components'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useCallback } from 'react'

export type ActionMainButtonMode =
  | 'connect-wallet'
  | 'no-connection'
  | 'restricted'
  | 'confirming'
  | 'enter-amount'
  | 'insufficient-funds'
  | 'no-liquidity'
  | 'trade'
  | 'long'
  | 'swap'
  | 'short'

type ActionMainButtonProps = {
  actionMainButtonMode: ActionMainButtonMode
  loading: boolean
  borrow: () => void
  swap: () => void
  short: () => void
}

export const ActionMainButton = ({ actionMainButtonMode, loading, borrow, swap, short }: ActionMainButtonProps) => {
  const { setVisible } = useWalletModal()
  const onConnect = useCallback(() => {
    trackWalletConnectGA()
    setVisible(true)
  }, [setVisible])

  function getLabelByMode(mode: ActionMainButtonMode) {
    switch (mode) {
      case 'connect-wallet':
        return 'Connect Wallet'
      case 'no-connection':
        return 'No Connection'
      case 'confirming':
        return 'Confirming...'
      case 'restricted':
        return 'Restricted'
      case 'enter-amount':
        return 'Enter Amount'
      case 'insufficient-funds':
        return 'Insufficient Funds'
      case 'no-liquidity':
        return 'No Liquidity'
      case 'trade':
        return 'Trade'
      case 'swap':
        return 'Swap'
      case 'long':
        return 'Long'
      case 'short':
        return 'Short'
      default:
        return 'Restricted'
    }
  }

  function getColorByMode(mode: ActionMainButtonMode) {
    switch (mode) {
      case 'connect-wallet':
      case 'swap':
        return 'solid'
      case 'no-connection':
      case 'confirming':
      case 'restricted':
      case 'enter-amount':
      case 'insufficient-funds':
      case 'no-liquidity':
        return 'main'
      case 'trade':
        return 'gradient'
      case 'long':
        return 'green'
      case 'short':
        return 'red'
      default:
        return 'main'
    }
  }

  function getClickHandlerByMode(mode: ActionMainButtonMode) {
    switch (mode) {
      case 'trade':
      case 'long':
        return borrow()
      case 'swap':
        return swap()
      case 'short':
        return short()
      case 'connect-wallet':
        return onConnect()
      default:
        return null
    }
  }

  function isDisabledByMode(mode: ActionMainButtonMode) {
    switch (mode) {
      case 'no-connection':
      case 'restricted':
      case 'enter-amount':
      case 'insufficient-funds':
        return true
      case 'trade':
      case 'swap':
        return loading
      default:
        return false
    }
  }

  return (
    <Button
      className='w-[160px] !px-0'
      color={getColorByMode(actionMainButtonMode)}
      disabled={isDisabledByMode(actionMainButtonMode)}
      size='m'
      onClick={() => getClickHandlerByMode(actionMainButtonMode)}
    >
      {getLabelByMode(actionMainButtonMode)}
    </Button>
  )
}
